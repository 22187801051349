import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  useBreakpointValue,
  useRadioGroup,
  HStack,
  VStack,
  Text,
  Icon,
  InputProps,
  Stack,
} from '@chakra-ui/react'
import { IconType } from 'react-icons/lib'
import { BiCreditCard } from 'react-icons/bi'
import { BsBank2 } from 'react-icons/bs'
import { EmbedProps } from 'components/Widget/constants'
import { PaymentTypeEnum } from 'src/generated/graphql-backend'
import { ProductInput } from 'src/generated/graphql-frontend'
import { StrikeThrough } from '../StrikeThrough'
import { RadioOption, BankTransfer, StripeCheckout, PaymentRequestComponent } from './components'
import { PaymentSubmitResult, PersonalInfo, SetSubmitHandlerRef } from './types'
import { CurrencyType } from 'utils/payments'

type ProductSettingsType = NonNullable<EmbedProps['profile']['productSettings']>

interface Props {
  name: string
  amountCents: number
  currency: CurrencyType
  productInput: ProductInput
  isReccuringDonation?: boolean
  isEmbed?: boolean
  size?: InputProps['size']
  hasPaymentTitle?: boolean
  errorMessage?: string
  colorScheme?: ProductSettingsType['widgetColorScheme']
  bankConnection?: ProductSettingsType['BankConnections'][number]
  getEmail(): string
  setSubmitHandlerRef?: SetSubmitHandlerRef
  value?: PaymentTypeEnum
  onObtainedPersonalInfo?(personalInfo: PersonalInfo): Promise<void>
  onChange(newValue: PaymentTypeEnum): void
  onGoNext(paymentResult: PaymentSubmitResult): void
}

export const PaymentMethodOptions = React.forwardRef<SetSubmitHandlerRef, Props>(
  (
    {
      name,
      amountCents,
      currency,
      productInput,
      isReccuringDonation,
      isEmbed,
      size,
      hasPaymentTitle,
      colorScheme,
      bankConnection,
      getEmail,
      errorMessage,
      value,
      onChange,
      onObtainedPersonalInfo,
      onGoNext,
    },
    ref
  ) => {
    const { t } = useTranslation('common')
    const isBreakpoint = useBreakpointValue({ base: true, md: false })
    const [paymentError, setPaymentError] = React.useState<string | undefined>(undefined)
    const Wrapper = isEmbed || isBreakpoint ? VStack : HStack
    const { getRootProps, getRadioProps } = useRadioGroup({
      name,
      value,
      onChange,
    })
    const group = getRootProps()
    const groupHasValue = value !== undefined

    const options = React.useMemo(
      () =>
        [
          { value: PaymentTypeEnum.Card, icon: BiCreditCard },
          !isReccuringDonation &&
            bankConnection && { value: PaymentTypeEnum.BankTransfer, icon: BsBank2 },
        ].filter(Boolean) as { value: PaymentTypeEnum; icon: IconType }[],
      [bankConnection, isReccuringDonation]
    )
    const checkoutCompProps = React.useMemo(
      () => ({
        colorScheme,
        amountCents,
        currency,
        productInput,
        isReccuringDonation,
        getEmail,
        setPaymentError,
        onObtainedPersonalInfo,
        ref,
      }),
      [colorScheme, amountCents, currency, productInput, isReccuringDonation, onObtainedPersonalInfo, getEmail, ref]
    )
    return (
      <>
        <Stack spacing="2">
          <Wrapper alignItems="stretch" spacing="2" {...group}>
            {options.map(({ value, icon }) => {
              const radio = getRadioProps({ value })
              return (
                <RadioOption
                  key={value}
                  icon={<Icon as={icon} />}
                  isEmbed={isEmbed}
                  isBreakpoint={isBreakpoint}
                  {...radio}
                  groupHasValue={groupHasValue}
                  colorScheme={colorScheme}
                >
                  {t(value as string)}
                </RadioOption>
              )
            })}
          </Wrapper>
          <PaymentRequestComponent {...checkoutCompProps} onGoNext={onGoNext} />
        </Stack>
        {(paymentError || errorMessage) && (
          <Text pt="1" fontSize="sm" color="red.400">
            {paymentError || errorMessage}
          </Text>
        )}
        {value && (
          <VStack alignItems="stretch">
            {value === PaymentTypeEnum.Card && (
              <>
                {hasPaymentTitle && (
                  <StrikeThrough>
                    <Text fontSize="sm">{t('cardDetails')}</Text>
                  </StrikeThrough>
                )}
                <StripeCheckout {...checkoutCompProps} size={size} />
              </>
            )}
            {value === PaymentTypeEnum.BankTransfer && (
              <>
                {hasPaymentTitle && (
                  <StrikeThrough>
                    <Text fontSize="sm">{t('BankTransfer')}</Text>
                  </StrikeThrough>
                )}
                <BankTransfer {...checkoutCompProps} />
              </>
            )}
          </VStack>
        )}
      </>
    )
  }
)

PaymentMethodOptions.displayName = 'PaymentMethodOptions'
