import React from 'react'
import { Box, Stack, HStack, Tag, useBreakpointValue, Select } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { OrderStatusEnum } from 'src/generated/graphql-backend'
import { useLocalStorage } from 'utils/useLocalStorage'
import { Label } from 'components/Label'
import { OrdersGrid } from './components'
import { NewProductProps } from './types'
import { getFadeGradient } from 'utils/theme/helpers'

export const scrollOverlayStyle = {
  content: '""',
  position: 'absolute',
  right: 0,
  top: 0,
  display: 'block',
  width: '16px',
  height: '100%',
  backgroundImage: getFadeGradient(100),
  // TODO: Use theme
  zIndex: 1,
}

const possibleFilters = [
  OrderStatusEnum.Paid,
  OrderStatusEnum.WaitingMerchant,
  OrderStatusEnum.PendingPayment,
  OrderStatusEnum.OnHold,
  OrderStatusEnum.Processing,
  OrderStatusEnum.Shipped,
  OrderStatusEnum.Completed,
  OrderStatusEnum.Cancelled,
  OrderStatusEnum.Refunded,
]

export const OrdersTab: React.FC<NewProductProps> = ({ onNewProduct }) => {
  const { t } = useTranslation('order')
  const [filters, setFilters] = useLocalStorage('backoffice-orders-status', [OrderStatusEnum.Paid])
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const toggleFilter = React.useCallback((newFilter: OrderStatusEnum) => {
    setFilters((filters) =>
      filters[0] === newFilter ? [] : [newFilter]
    )
  }, [])
  return (
    <Stack>
      <Box
        position="relative"
        mx={{ base: 0, lg: -4 }}
        {...(!isMobile && {
          '_after': scrollOverlayStyle,
          '_before': {
            ...scrollOverlayStyle,
            left: 0,
            top: 0,
            transform: 'rotate(180deg)',
          },
        })}
      >
        {isMobile ? (
          <Stack w="full">
            <Label>{t('backOffice:ShopOrder.headingOrderStatus')}</Label>
            <Select
              variant="outline"
              size={{ base: 'sm', md: 'md' }}
              value={filters}
              onChange={(e) => setFilters([e.target.value as OrderStatusEnum])}
            >
              {possibleFilters.map((key) => (
                <option key={key} value={key}>
                  {t(key)}
                </option>
              ))}
            </Select>
          </Stack>
        ) : (
          <HStack overflow="hidden" overflowX="scroll" px={{ base: 2, md: 4 }}>
            {possibleFilters.map((key) => (
              <Tag
                key={key}
                flexShrink={0}
                size={{ base: 'sm', md: 'md' }}
                cursor="pointer"
                {...(filters.includes(key)
                  ? { variant: 'solid', bgColor: 'white', color: 'primary.500' }
                  : { variant: 'outline' })}
                onClick={() => toggleFilter(key)}
              >
                {t(key)}
              </Tag>
            ))}
          </HStack>
        )}
      </Box>
      <OrdersGrid
        filterData={{
          status: filters,
        }}
        onNewProduct={onNewProduct}
      />
    </Stack>
  )
}
