import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <chakra.img
    width="500"
    height="200"
    viewBox="0 0 500 200"
    fill="none"
    objectFit="contain"
    xmlns="http://www.w3.org/2000/svg"
    src="/images/esoteriq-logo.svg"
    {...props}
  />
)

export const LogoIcon = (props: HTMLChakraProps<'svg'>) => (
  <chakra.img
    width="123"
    height="152"
    viewBox="0 0 123 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    src="/images/esoteriq-logo-hand.svg"
    {...props}
  />
)

export default Logo
