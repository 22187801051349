import React from 'react'
import { keyframes, Center, Box, VStack, Heading } from '@chakra-ui/react'
import Container from 'components/Container'
import { Card } from 'components/Card'
import { LogoIcon } from 'components/Logo'
import useTranslation from 'next-translate/useTranslation'

const pulse = keyframes`
  0% {opacity: 1}
  50% {opacity: 0.45}
  100% {opacity: 1}
`

export const LoadingScreen: React.FC<{ loadingText?: string }> = ({ loadingText }) => {
  const { t } = useTranslation('common')
  return (
    <Container py={{ base: 16, md: 24 }}>
      <Center>
        <Card p={{ base: 8, md: 20 }} pb={{ base: 6, md: 12 }} textAlign="center">
          <VStack spacing={{ base: '8', md: '14' }}>
            <Box width={{ base: '100px', md: '140px' }} height={{ base: '20', md: '28' }}>
              <LogoIcon width="100%" height="100%" />
            </Box>
            <Heading size="lg" animation={`${pulse} 1s infinite`}>
              {loadingText || t('Initializing')}
            </Heading>
          </VStack>
        </Card>
      </Center>
    </Container>
  )
}
