import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

export interface CalendarButtonProps extends ButtonProps {
  isAvailable?: boolean
  isSelected?: boolean
}

export const CalendarButton: React.FC<CalendarButtonProps> = ({
  isAvailable,
  isSelected,
  ...props
}) => (
  <Button
    minW="0"
    w="100%"
    h="100%"
    py="0"
    px="0"
    fontSize={{ base: 'sm', sm: 'md', md: 'sm' }}
    borderRadius={{ base: 'md', sm: 'xl', md: 'md' }}
    border="none"
    {...((isSelected && {
      colorScheme: 'primary',
      color: 'primary',
      borderStyle: 'solid',
      borderColor: 'primary.300',
      borderWidth: '1px',
    }) ||
      (isAvailable && {
        colorScheme: 'purple.200',
        backgroundColor: 'purple.800',
        color: 'teal.100',
        borderStyle: 'solid',
        borderColor: 'whiteAlpha.200',
        borderWidth: '1px',
      }) || { isDisabled: true })}
    _disabled={{
      bg: 'whiteAlpha.200',
      // TODO: Not color accessible
      color: 'teal.200',
      cursor: 'not-allowed',
    }}
    _hover={{
      _disabled: {
        bg: 'whiteAlpha.200',
        color: 'teal.200',
      },
    }}
    {...props}
  />
)
