import React from 'react'
import { StripeCardElementOptions } from '@stripe/stripe-js'
import { CardElement } from '@stripe/react-stripe-js'
import { Text, Input, InputProps, Box, Spinner, useDisclosure, useColorModeValue, useToken } from '@chakra-ui/react'
import { EmbedProps } from './Widget/constants'
import { ErrorMessage } from './ErrorMessage'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  cardError?: string
  setCardError?: (error?: string) => void
  colorScheme?: NonNullable<EmbedProps['profile']['productSettings']>['widgetColorScheme']
  size: InputProps['size']
  isLoading?: boolean
}

export const StripeCard: React.FC<Props> = ({
  cardError,
  setCardError,
  colorScheme: colorSchemeProp,
  size,
  isLoading,
}) => {
  const { t } = useTranslation('common')
  const { isOpen: isFocused, onOpen: onFocus, onClose: onBlur } = useDisclosure()
  const defaultColorScheme = useColorModeValue('primary.300', 'white')
  const colorScheme = colorSchemeProp || defaultColorScheme
  const borderColor = isFocused ? (cardError && 'red.500') || colorScheme : 'teal.200'
  const themeColor = useToken('colors', colorScheme)
  const primary = useToken('colors', 'primary.500')
  const gray = useToken('colors', 'gray.500')
  const isDarkMode = useColorModeValue(false, true)
  const cardElementOptions: StripeCardElementOptions = React.useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          padding: '16px',
          '::placeholder': {
            color: isDarkMode ? primary : gray,
          },
          color: colorScheme ? themeColor : primary,
          fontFamily: 'Rubik, sans-serif',
        },
      },
    }),
    [colorScheme, gray, isDarkMode, primary, themeColor]
  )
  if (isLoading) {
    return (
      <Input
        as="div"
        bg="transparent"
        size={size}
        display="flex"
        justifyContent="center"
        alignItems="center"
        pointerEvents="none"
      >
        <Spinner size="md" thickness="3px" />
        <Text pl="4" fontSize="md" fontWeight="medium">
          {t('loadingCardPayment')}
        </Text>
      </Input>
    )
  }
  return (
    <div>
      <Input
        as="div"
        variant="outline"
        boxShadow={`0 0 0 1px ${colorScheme ? themeColor : borderColor}`}
        display="flex"
        alignItems="center"
        size={size}
      >
        <Box flex="1">
          <CardElement
            options={cardElementOptions}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => setCardError?.(e.error?.message)}
          />
        </Box>
      </Input>
      {cardError && <ErrorMessage>{cardError}</ErrorMessage>}
    </div>
  )
}
