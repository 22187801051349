import { Button, chakra, Icon, IconButton, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React from 'react'
import { BsHeartFill } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'
import { getIsLoggedIn, getRoute } from 'utils/helpers'

export const SignUpChip = () => {
  const { t } = useTranslation('homePage')
  const bg = useColorModeValue('white', 'gray.700')
  const { data } = useSession()
  const isLoggedIn = getIsLoggedIn(data)
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: !isLoggedIn })

  React.useEffect(() => {
    if (isLoggedIn) {
      onClose()
    }
  }, [isLoggedIn])

  return isOpen ? (
    <chakra.div
      pl={{ base: 3, md: 4 }}
      pr={9}
      py={{ base: 1.5, md: 2.5 }}
      backgroundColor={bg}
      boxShadow="xl"
      borderRadius="md"
      position="fixed"
      bottom={6}
      left="50%"
      transform="translateX(-50%)"
      zIndex="banner"
      textAlign="center"
      width={{ base: '90%', md: '100%' }}
      maxW="max-content"
      pointerEvents="none"
    >
      <Icon as={BsHeartFill} color="red.400" boxSize={3} transform="translateY(2px)" />
      <Text as="span" fontSize="sm" fontWeight="medium" pl="3">
        {t('SignUpChip.heading', { platformName: process.env.NEXT_PUBLIC_APP_NAME })}
      </Text>
      <Text as="span" fontSize="sm" pl="1.5">
        {t('SignUpChip.paragraph')}
      </Text>
      <Button
        size="sm"
        fontWeight="medium"
        textDecoration="underline"
        variant="link"
        as={Link}
        color="green.500"
        href={getRoute('signup')}
        display="inline-block"
        pointerEvents="all"
        pl="3"
      >
        {t('SignUpChip.button')}
      </Button>
      <IconButton
        position="absolute"
        right={1}
        top="50%"
        transform="translateY(-50%) scale(0.87)"
        variant="ghost"
        size="sm"
        aria-label="close"
        icon={<GrClose />}
        opacity={0.5}
        onClick={onClose}
        pointerEvents="all"
      />
    </chakra.div>
  ) : null
}
