import React from 'react'
import Link from 'next/link'
import {
  Box,
  Stack,
  AspectRatio,
  BoxProps,
  Button,
  Heading,
  Text,
  Flex,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react'
import { ProductTypeEnum, ProductsQuery } from 'src/generated/graphql-frontend'
import { getPrice } from 'utils/payments'
import { getRoute } from 'utils/helpers'
import { TextTag } from 'components/TextTag'
import { useProductCategories } from 'components/BackOffice/Shop'
import { useShopContext } from './ShopContext'
import useTranslation from 'next-translate/useTranslation'
import { useTipTapJsonToHtml } from 'components/TextEditor'
import CardButton from 'components/CardButton'

const spacing = { base: 5, sm: 6, lg: 6 }

export const ProductCard: React.FC<
  Partial<ProductsQuery['products']['products'][number]> & { username?: string } & BoxProps
> = ({ id, name, description, Pictures, Price, username, hasOptions, type, slug, ...rest }) => {
  const { t } = useTranslation('username')
  const productCategories = useProductCategories()
  const [isAddingToCart, setIsAddingToCart] = React.useState(false)
  const { addToCart } = useShopContext()
  const descriptionHtml = useTipTapJsonToHtml({ json: description })
  const isLoaded = !!id
  const href =
    !!username && !!slug ? getRoute('profile', username, { productSlug: slug }) : undefined

  return (
    <CardButton
      href={href}
      flex="1"
      padding={{ base: 4, sm: 6, lg: 10 }}
      alignItems="center"
      borderRadius={{ base: 'xl', md: '5xl' }}
      border="1px solid transparent"
      {...rest}
    >
      <Stack h="full" spacing={spacing}>
        <Box
          borderRadius={{ base: 'xl', md: '5xl' }}
          bg={Pictures ? `url(${Pictures[0]?.thumbnailSignedUrl}) center` : undefined}
          bgSize="cover"
          width="full"
        >
          <Skeleton isLoaded={isLoaded} borderRadius="inherit">
            <AspectRatio maxH="inherit" ratio={1.42}>
              <div />
            </AspectRatio>
          </Skeleton>
        </Box>
        <Stack
          flex="1"
          spacing={{
            base: 4,
            lg: 4,
          }}
        >
          <Skeleton alignSelf="flex-start" isLoaded={isLoaded}>
            <Heading size="md" noOfLines={2}>
              {name || t('Shop.productCardLoadingProduct')}
            </Heading>
          </Skeleton>
          <Skeleton alignSelf="flex-start" isLoaded={isLoaded}>
            <TextTag opacity={0.6}>
              {isLoaded
                ? productCategories.find(({ key }) => key === type)?.label
                : t('Shop.productCardGenericCategory')}
            </TextTag>
          </Skeleton>
          <SkeletonText isLoaded={isLoaded} noOfLines={3}>
            <Text fontSize="xs" noOfLines={4} opacity={0.6}>
              {descriptionHtml}
            </Text>
          </SkeletonText>
        </Stack>
        <Flex flex="none" alignItems="center" justifyContent="space-between">
          <Skeleton isLoaded={isLoaded}>
            <Heading size="xl">{Price ? getPrice(Price.amount, Price.currency) : '0000e'}</Heading>
          </Skeleton>
          <Skeleton isLoaded={isLoaded}>
            {type === ProductTypeEnum.Booking ? (
              <Button as={Link} href={href} variant="solid">
                {t('Shop.bookNow')}
              </Button>
            ) : (
              <Button
                variant="solid"
                isLoading={isAddingToCart}
                onClick={
                  isLoaded
                    ? async (event) => {
                        event.preventDefault()
                        setIsAddingToCart(true)
                        await addToCart({ productId: id, hasOptions })
                        setIsAddingToCart(false)
                      }
                    : undefined
                }
              >
                {t('Shop.productCardAddToCart')}
              </Button>
            )}
          </Skeleton>
        </Flex>
      </Stack>
    </CardButton>
  )
}
