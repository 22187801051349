import * as React from 'react'
import { Box, HStack, VStack, Icon, IconButton } from '@chakra-ui/react'
import styled, { css } from 'styled-components'
import { Content, EditorOptions, generateText } from '@tiptap/core'
import { JSONContent } from '@tiptap/react'
import { generateHTML } from '@tiptap/html'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import { Level } from '@tiptap/extension-heading'
import StarterKit from '@tiptap/starter-kit'
import {
  FaBold,
  FaItalic,
  FaStrikethrough,
  FaCode,
  FaListUl,
  FaListOl,
  FaRegFileCode,
  FaUnderline,
} from 'react-icons/fa'
import { GrRotateLeft, GrRotateRight } from 'react-icons/gr'
import {
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
} from 'react-icons/lu'
import fontSizes from 'theme/foundations/fontSizes'
import { Label } from 'components/Label'

const commonCss = css`
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  h1 {
    font-size: ${fontSizes['5xl']};
  }

  h2 {
    font-size: ${fontSizes['4xl']};
  }

  h3 {
    font-size: ${fontSizes['3xl']};
  }

  h4 {
    font-size: ${fontSizes['2xl']};
  }

  h5 {
    font-size: ${fontSizes.xl};
  }

  h5 {
    font-size: ${fontSizes.lg};
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
`

const ContentWrapper = styled(Box)`
  ${commonCss}
`

const Wrapper = styled(Box)`
  &,
  * {
    outline: none;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  /* Basic editor styles */
  .ProseMirror {
    ${commonCss}
  }
`

const MenuBar = ({ editor }: { editor: Editor }) => {
  if (!editor) {
    return null
  }

  return (
    <VStack
      pb="2"
      justify="flex-start"
      align="flex-start"
      borderBottom="1px solid"
      borderBottomColor="teal.200"
      mb="2"
    >
      <HStack>
        {[LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6].map(
          (icon, index) => (
            <IconButton
              key={index}
              aria-label={`heading ${index}`}
              size="sm"
              icon={<Icon as={icon} />}
              variant={editor.isActive('heading', { level: index + 1 }) ? 'solid' : 'outline'}
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHeading({ level: (index + 1) as Level })
                  .run()
              }
            />
          )
        )}
      </HStack>

      <HStack flexWrap="wrap">
        <IconButton
          aria-label="bold"
          size="sm"
          icon={<Icon as={FaBold} />}
          variant={editor.isActive('bold') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        />
        <IconButton
          aria-label="italic"
          size="sm"
          icon={<Icon as={FaItalic} />}
          variant={editor.isActive('italic') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        />
        <IconButton
          aria-label="italic"
          size="sm"
          icon={<Icon as={FaUnderline} />}
          variant={editor.isActive('underline') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        />
        <IconButton
          aria-label="strike"
          size="sm"
          icon={<Icon as={FaStrikethrough} />}
          variant={editor.isActive('strike') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
        />
        <IconButton
          aria-label="code"
          size="sm"
          icon={<Icon as={FaCode} />}
          variant={editor.isActive('code') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
        />
        <IconButton
          aria-label="bulletList"
          size="sm"
          icon={<Icon as={FaListUl} />}
          variant={editor.isActive('bulletList') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <IconButton
          aria-label="orderedList"
          size="sm"
          icon={<Icon as={FaListOl} />}
          variant={editor.isActive('orderedList') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
        <IconButton
          aria-label="codeBlock"
          size="sm"
          icon={<Icon as={FaRegFileCode} />}
          variant={editor.isActive('codeBlock') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        />
        <IconButton
          aria-label="undo"
          size="sm"
          icon={<Icon as={GrRotateLeft} />}
          variant={editor.isActive('codeBlock') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        />
        <IconButton
          aria-label="redo"
          size="sm"
          icon={<Icon as={GrRotateRight} />}
          variant={editor.isActive('codeBlock') ? 'solid' : 'outline'}
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        />
      </HStack>
    </VStack>
  )
}

export const useEditorState = ({
  initialContent = null,
  placeholder = '',
  ...options
}: { initialContent?: Content | null; placeholder?: string } & Partial<EditorOptions>) => {
  const editor = useEditor({
    ...options,
    extensions: [
      Placeholder.configure({
        placeholder,
      }),
      Underline,
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      // TODO
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
    ],
    content: initialContent,
  })

  return editor
}

const editorExtensions = [
  Underline,
  Color.configure({types: [TextStyle.name, ListItem.name]}),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  TextStyle.configure({types: [ListItem.name]}),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
]

export const useTipTapJsonToHtml = ({ json = '' }: { json?: string | null }) => {
  const output = React.useMemo(
    () => {
      try {
        return generateHTML(JSON.parse(json as string) as JSONContent, editorExtensions)
      } catch {
        return json
      }
    },
    [json]
  )

  return output ? <ContentWrapper dangerouslySetInnerHTML={{ __html: output }} /> : null
}


export const useTipTapJsonToPlainText = ({ json = '' }: { json?: string | null }) => {
  const output = React.useMemo(
    () => {
      try {
        return generateText(JSON.parse(json as string) as JSONContent, editorExtensions).split('\n').join(' ')
      } catch {
        return json
      }
    },
    [json]
  )

  return output
}

interface Props {
  editor: Editor
  label?: string
}

export const TextEditor: React.FC<Props> = ({ label, editor }) => (
  <Box>
    {label && <Label>{label}</Label>}
    <Wrapper border="1px solid" borderColor="teal.200" p="2" borderRadius="lg">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </Wrapper>
  </Box>
)
