import { Box } from '@chakra-ui/react'
import { Props as ChakraSelectProps, Select } from 'chakra-react-select'
import { forwardRef, useMemo } from 'react'
import { InferRef } from 'src/types/general'
import { ErrorMessage } from './ErrorMessage'

export interface ReactSelectProps extends Omit<ChakraSelectProps, 'isMulti'> {
  errorMessage?: string
  isRequired?: boolean
  isMulti?: boolean
}

export const ReactSelect = forwardRef<InferRef<typeof Select>, ReactSelectProps>(
  ({ errorMessage, isDisabled, onChange, ...rest }, ref) => {
    const { options, value, isMulti } = rest
    // Used to scroll to the selected value, otherwise react-select will not scroll to the selected value
    const selectValue = useMemo(
      () =>
        isMulti || !options
          ? value
          : options.find((option) =>
              option && typeof option === 'object'
                ? 'value' in option &&
                  (option.value === (value as { value: any } | undefined)?.value ||
                    option.value === value)
                : value === option
            ),
      [options, value, isMulti]
    )
    return (
      <Box {...(isDisabled && { cursor: 'not-allowed', pointerEvents: 'none' })} color="wheat">
        <Select
          ref={ref}
          {...rest}
          colorScheme="primary"
          // chakraStyles={{
          //   multiValue: (provided, state) => ({
          //     ...provided,
          //     color: 'primary.500',
          //   }),
          // }}
          isInvalid={!!errorMessage}
          menuShouldScrollIntoView
          value={selectValue}
          onChange={isDisabled ? undefined : onChange}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Box>
    )
  }
)

ReactSelect.displayName = 'ReactSelect'
