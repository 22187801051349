import { Flex, Icon, Tag } from '@chakra-ui/react'
import { UseFormWatch } from 'react-hook-form'
import { ProfileInput } from 'src/generated/graphql-frontend'
import { SocialMediaIcons, SocialMediaNetworkType } from './SocialMedia'

interface Props {
  watch: UseFormWatch<ProfileInput>
}

export const ProfileFormSocialMediaList = ({ watch }: Props) => {
  const accounts = watch('socialMediaAccounts')
  return (
    <Flex flexWrap="wrap" gap={2}>
      {accounts?.map((field) => {
        const SocialIcon = SocialMediaIcons[field.network as SocialMediaNetworkType].solid
        return (
          <Tag key={field.network} variant="outline" size="sm" colorScheme="gray" py={3} px={4}>
            <Icon as={SocialIcon} width={5} height={5} mr={2} />
            {field.url}
          </Tag>
        )
      })}
    </Flex>
  )
}
