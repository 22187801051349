import React from 'react'
import { Stack, Flex, Text, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ProductVariantQuery } from 'src/generated/graphql-frontend'
import { getCountryFromCode } from 'utils/helpers'
import { getPrice } from 'utils/payments'
import useTranslation from 'next-translate/useTranslation'
import { useTipTapJsonToHtml } from 'components/TextEditor'

export const tabStackSpacing = { base: '10', md: '12' }

// TODO: Sticky tabs and footer for saving changes, animation between tabs

interface Props {
  description?: string | null
  details?: NonNullable<ProductVariantQuery['productVariant']>['AttributeVariant']
  delivery?: NonNullable<ProductVariantQuery['productVariant']>['Price']['ShippingZones']
  currency: string
}

export const ProductTabs: React.FC<Props> = ({ description, details, delivery, currency }) => {
  const { t } = useTranslation('username')
  const hasDelivery = !!delivery?.length
  const descriptionHtml = useTipTapJsonToHtml({ json: description })
  return (
    <Tabs colorScheme="primary">
      <TabList mt="-2" color="gray.270">
        <Tab>{t('Shop.productTabsDescription')}</Tab>
        {!!details?.length && <Tab>{t('Shop.productTabsProductDetails')}</Tab>}
        {hasDelivery && <Tab>{t('Shop.productTabsDelivery')}</Tab>}
      </TabList>
      <TabPanels mt="8" opacity={0.65}>
        <TabPanel p="0">{descriptionHtml}</TabPanel>
        {!!details?.length && (
          <TabPanel p="0">
            {details
              .map(({ name, values: [{ value }] }) => ({
                label: name,
                value,
              }))
              .map(({ label, value }) => (
                <Flex key={label}>
                  <Text fontWeight="medium" pr="1">
                    {label}:
                  </Text>
                  <Text>{value}</Text>
                </Flex>
              ))}
          </TabPanel>
        )}
        {hasDelivery && (
          <TabPanel p="0">
            <Stack spacing="2.5">
              {delivery.map(({ id, amount, countryCodes, isOther, freeAbove }) => (
                <div key={id}>
                  {countryCodes.length > 0 && (
                    <Flex>
                      <Text fontWeight="medium" pr="1">
                        {t('Shop.productTabsCountries')}
                      </Text>{' '}
                      {countryCodes.map((code) => getCountryFromCode(code)?.label).join(', ')}
                    </Flex>
                  )}
                  {isOther && (
                    <Text fontWeight="medium" pr="1">
                      {t('Shop.productTabsInternational')}
                    </Text>
                  )}
                  <Flex>
                    <Text fontWeight="medium" pr="1">
                      {t('Shop.productTabsPrice')}
                    </Text>{' '}
                    {getPrice(amount, currency)}
                  </Flex>
                  {typeof freeAbove === 'number' && (
                    <Flex>
                      <Text fontWeight="medium" pr="1">
                        {t('Shop.productTabsFreeShipping')}
                      </Text>{' '}
                      {t('Shop.productTabsFreeShippingAbove')} {getPrice(freeAbove, currency)}
                    </Flex>
                  )}
                </div>
              ))}
            </Stack>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}
