import React from 'react'
import { Button, ButtonProps, Box } from '@chakra-ui/react'
import { darken,lighten } from 'polished'
import { LogoIcon } from '../Logo'
import { widgetColors, WidgetColorType } from './constants'
import colors from 'theme/foundations/colors'

interface WidgetButtonProps extends ButtonProps {
  colorScheme: WidgetColorType
}

export const WidgetButton: React.FC<WidgetButtonProps> = ({ colorScheme, ...props }) => {
  const cs = widgetColors[colorScheme]
  const [colorKey, gradeKey] = colorScheme.split('.') as [colorKey: keyof typeof colors, gradeKey: number]
  const colorSchemeHex = colors[colorKey][gradeKey] as string
  return (
    <Button
      {...props}
      variant="ghost"
      bg={colorScheme}
      color={widgetColors[colorScheme]}
      iconSpacing="3.5"
      fontWeight="medium"
      fontSize="lg"
      px="4"
      pr="8"
      py="2.5"
      leftIcon={
        <Box width="38px" height="38px" p="1" borderRadius="full" bg="white">
          <LogoIcon width="100%" height="100%" />
        </Box>
      }
      _hover={{
        backgroundColor: lighten(0.075, colorSchemeHex),
        color: cs,
      }}
      _active={{
        backgroundColor: darken(0.075, colorSchemeHex),
        color: cs,
      }}
    />
  )
}
