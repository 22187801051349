import React from 'react'
import { chakra, useColorModeValue } from '@chakra-ui/react'
import { headerHeight } from './useHeaderHeight'

const EASING = '0.25s ease-in-out'

const HeaderStrip: React.FC<{ children: React.ReactNode; isScroll?: boolean }> = ({
  isScroll,
  children,
}) => {
  const bg = useColorModeValue('white', 'rgb(23 14 27 / 64%)')
  const shadowValue = useColorModeValue('0px 14px 40px rgba(132, 132, 132, 0.14)',  '0px 8px 32px rgb(190 102 53 / 10%)')

  return (
    <chakra.header
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pos="fixed"
      top="0"
      left="0"
      right="0"
      width="full"
      height={headerHeight}
      transition={`box-shadow ${EASING}, background-color ${EASING}`}
      bg={isScroll ? bg : 'transparent'}
      shadow={isScroll ? shadowValue : undefined}
      zIndex="sticky"
      backdropFilter="auto"
      backdropBlur="4px"
      backdropBrightness="120%"
      backdropContrast="105%"
      backdropHueRotate="19deg"
    >
      <chakra.div width="100%" mx="auto" maxW="7xl">
        <chakra.div mx="auto" maxW="7xl">
          {children}
        </chakra.div>
      </chakra.div>
    </chakra.header>
  )
}

export { HeaderStrip }
