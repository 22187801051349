import React from 'react'
import { Box, Flex, Text, Icon, Stack, IconButton } from '@chakra-ui/react'
import { FaLink } from 'react-icons/fa'
import { ProfileQueryResult } from 'src/generated/graphql-frontend'
import { SocialMediaIcons, SocialMediaNetworkType } from 'components/SocialMedia'
import { SupportersText } from 'components/SupportersText'
import { getProfileURL } from 'utils/helpers'

export const AboutTab: React.FC<{
  profile: NonNullable<NonNullable<ProfileQueryResult['data']>['profile']>
}> = ({ profile }) => (
  <>
    <Text>{profile.description}</Text>
    {(profile.websiteUrl || profile.Website?.domain) && (
      <Flex alignItems="center">
        <Icon as={FaLink} width="4" height="4" mr="2.5" />
        <a href={profile.Website?.domain || profile.websiteUrl!} rel="noreferrer" target="_blank">
          <Text fontWeight="medium">{profile.Website?.domain || profile.websiteUrl}</Text>
        </a>
      </Flex>
    )}
    <Stack direction="row" spacing="4">
      {profile?.socialMediaAccounts?.map((acc) => {
        const SocialIcon = SocialMediaIcons[acc.network as SocialMediaNetworkType].solid
        return (
          <IconButton
            key={acc.id}
            size="xs"
            variant="unstyled"
            aria-label={acc.network}
            icon={<Icon as={SocialIcon} height={6} width={6} />}
            as="a"
            target="_blank"
            href={getProfileURL(acc)}
            style={{ boxShadow: 'none' }}
          />
        )
      })}
    </Stack>
    <Box bg="gray.200" borderRadius="md" py="2" px="5" mt="6">
      <Text fontSize="sm" color="gray.400">
        <SupportersText count={profile.supportersCount} />
      </Text>
    </Box>
  </>
)
