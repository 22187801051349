import React from 'react'
import { useDisclosure, Button, Icon, Stack, Heading, Flex, Text } from '@chakra-ui/react'
import { FiLink } from 'react-icons/fi'
import { Control, useFieldArray, UseFormGetValues, UseFormRegister } from 'react-hook-form'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import {
  Profile,
  ProfileInput,
  ProfileQueryResult,
  useUpdateProfileMutation,
} from 'src/generated/graphql-frontend'
import { URLEnum } from 'src/constants'
import { getProfileURL } from 'utils/helpers'
import { NetworkForm } from './NetworkForm'
import { SocialMediaNetworkType } from '.'
import { SocialMediaIcons, socialMediaOnlyUsername } from './SocialMedia'
import useTranslation from 'next-translate/useTranslation'

type SocialMediaAccount = Partial<
  Omit<NonNullable<NonNullable<Profile['socialMediaAccounts']>[0]>, 'createdAt' | 'updatedAt'>
>

export type SocialMediaAccounts = SocialMediaAccount[]

interface ConnectProps {
  control: Control<ProfileInput, object>
  register: UseFormRegister<ProfileInput>
  getValues: UseFormGetValues<ProfileInput>
  profile?: NonNullable<ProfileQueryResult['data']>['profile']
}

const socialMediaNetworkTypeArray: SocialMediaNetworkType[] = [
  'facebook',
  'instagram',
  'spotify',
  'twitch',
  'twitter',
  'youtube',
  'tiktok',
]

export const getProcessedSocialMediaAccounts = (
  socialMediaAccounts: ProfileInput['socialMediaAccounts']
) =>
  socialMediaAccounts
    ?.filter((acc) => acc.url)
    .map((acc) => {
      if (socialMediaOnlyUsername.includes(acc.network as SocialMediaNetworkType)) {
        return { ...acc, url: acc.url }
      }
      if (acc.url?.startsWith(URLEnum.https)) {
        return acc
      }
      return acc.url?.startsWith(URLEnum.www)
        ? { ...acc, url: `${URLEnum.https}://${acc.url}` }
        : { ...acc, url: getProfileURL(acc) }
    })

export const Connect = React.forwardRef<HTMLButtonElement, ConnectProps>(
  ({ control, register, getValues, profile }, ref) => {
    const { t } = useTranslation('profile')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { fields, append, remove } = useFieldArray({ control, name: 'socialMediaAccounts' })
    const [updateProfile, updateProfileData] = useUpdateProfileMutation()
    const onSocialNetworksSubmit = React.useCallback(async () => {
      const socialMediaAccounts = getValues('socialMediaAccounts')
      if (profile) {
        const inputData: ProfileInput = {
          socialMediaAccounts: getProcessedSocialMediaAccounts(socialMediaAccounts),
        }
        await updateProfile({
          variables: {
            id: profile.id,
            inputData,
          },
        })
      }
      onClose()
    }, [profile])

    const socialNetworks = socialMediaNetworkTypeArray
      .map((network) => {
        const NetworkIcon = SocialMediaIcons[network].colored
        return fields.find((acc) => acc.network === network) ? null : (
          <Button
            py="3"
            px="5"
            variant="solid"
            colorScheme="gray.200"
            borderRadius="lg"
            color="primary.500"
            leftIcon={<NetworkIcon />}
            key={network}
            onClick={() => append({ network, url: '' })}
          >
            <Text fontWeight="medium" textTransform="capitalize">
              {network}
            </Text>
          </Button>
        )
      })
      .filter(Boolean)

    return (
      <>
        <Button
          ref={ref}
          alignSelf="flex-start"
          variant="outline"
          leftIcon={<Icon as={FiLink} />}
          onClick={onOpen}
        >
          {t('SocialNetworkControls.addEditSocialNetwork')}
        </Button>
        <ModalOrDrawer
          title={t('SocialNetworkControls.socialProfiles')}
          description={t('SocialNetworkControls.addLinksToAnySocialProfiles')}
          footer={
            <>
              <Button variant="outline" mr={2} onClick={onClose}>
                {t('SocialNetworkControls.cancel')}
              </Button>
              <Button onClick={onSocialNetworksSubmit} isLoading={updateProfileData.loading}>
                {t('SocialNetworkControls.saveChanges')}
              </Button>
            </>
          }
          maxW="5xl"
          isOpen={isOpen}
          onClose={onClose}
        >
          <Stack spacing="4">
            {fields.map(({ network }, index) => (
              <NetworkForm
                key={network}
                network={network as SocialMediaNetworkType}
                index={index}
                register={register}
                onRemove={() => remove(index)}
              />
            ))}
            {socialNetworks.length && (
              <>
                <Heading size="sm">{t('SocialNetworkControls.addEditSocialNetwork')}</Heading>
                <Flex direction="row" wrap="wrap" css={{ gap: '1rem' }}>
                  {socialNetworks}
                </Flex>
              </>
            )}
          </Stack>
        </ModalOrDrawer>
      </>
    )
  }
)

Connect.displayName = 'Connect'
