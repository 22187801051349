import React from 'react'
import { Box, VStack } from '@chakra-ui/react'
import { getRoute } from 'utils/helpers'
import { ProfileQueryResult } from 'src/generated/graphql-frontend'
import { CreatorPostsList } from 'components/CreatorPost'

interface Props {
  profile: NonNullable<NonNullable<ProfileQueryResult['data']>['profile']>
}

export const PostsTab: React.FC<Props> = ({ profile }) => (
  <Box w="full">
    <VStack mb="6" alignItems="stretch" spacing="4">
      <CreatorPostsList
        profile={profile}
        getPostHrefUrl={(profile, postId) => getRoute('posts', profile.username, postId)}
      />
    </VStack>
  </Box>
)
