import { SystemStyleFunction } from '@chakra-ui/theme-tools'

// const subtleVariant: SystemStyleFunction = () => ({

// TODO: https://v2.chakra-ui.com/docs/components/tag/theming
const outlineVariant: SystemStyleFunction = () => ({
  container: {
    bgColor: 'whiteAlpha.300',
    borderColor: 'whiteAlpha.500',
    borderWidth: 1,
    color: 'white',
  },
  _hover: {
    bgColor: 'whiteAlpha.400',
    borderColor: 'whiteAlpha.500',
    borderWidth: 1,
    color: 'white',
  },
})

const Tag = {
  baseStyle: {
    container: {
      minH: undefined,
      fontWeight: 'normal',
      transition: 'all 250ms ease-out',
    },
  },
  sizes: {
    sm: {
      container: {
        py: '3.5',
        px: '6',
        fontSize: 'sm',
        borderRadius: 'lg',
      },
    },
    md: {
      container: {
        minH: undefined,
        py: 4,
        px: 7,
        fontSize: 'md',
        borderRadius: 'xl',
      },
    },
  },
  variants: {
    outline: outlineVariant,
  },
  defaultProps: {
    colorScheme: 'primary',
  },
}

export default Tag
