import { Box, Button, Flex, HStack, Icon, IconButton, VStack } from '@chakra-ui/react'
import { BorderWrapper } from 'components/BorderWrapper'
import {
  CreatorPostCard,
  CreatorPostCardSubscribe,
  CreatorPostCardVideo,
} from 'components/CreatorPostCard'
import { EmptyTableContent } from 'components/DataTable'
import { LoadingGeneral } from 'components/LoadingGeneral'
import ShareProfileModal from 'components/Merchant/ShareProfileModal'
import { Pagination } from 'components/Pagination'
import { formatISO, parseISO } from 'date-fns'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React from 'react'
import { BiTrash } from 'react-icons/bi'
import { FiLink } from 'react-icons/fi'
import { DataLimit } from 'src/constants'
import {
  CreatorPostType,
  ProfileQueryResult,
  useCreatorPostsQuery,
} from 'src/generated/graphql-frontend'
import { getPageFromOffset, getRoute } from 'utils/helpers'
import { DeletePostModal } from './DeletePostModal'
import { DeletePostType } from './types'

type ProfileType = Pick<
  NonNullable<NonNullable<ProfileQueryResult['data']>['profile']>,
  'id' | 'username'
>
interface Props {
  profile: ProfileType
  getPostHrefUrl: (profile: ProfileType, postId: string) => string
}

export const CreatorPostsList: React.FC<Props> = ({ profile, getPostHrefUrl }) => {
  const { t } = useTranslation('creatorPost')
  const router = useRouter()
  const session = useSession()
  const page = router.query.page as string | undefined
  const componentMountDate = React.useMemo(() => new Date(), [])
  const [postToDelete, setPostToDelete] = React.useState<DeletePostType | null>(null)
  const { data, loading } = useCreatorPostsQuery({
    variables: {
      filterData: {
        profileIds: [profile.id].filter(Boolean),
        dateBefore: formatISO(componentMountDate),
      },
      pagination: {
        from: getPageFromOffset({ limitPerPage: DataLimit.products, page }),
        length: DataLimit.products,
      },
    },
  })
  const creatorPosts = data?.creatorPosts.creatorPosts
  const isProfileOwner = session.data?.user.profile?.id === profile.id

  return (
    <Box
      mt={{
        base: 5,
        md: 0,
      }}
      w="full"
    >
      <VStack mb="6" alignItems="stretch" spacing="4">
        {creatorPosts?.map((creatorPost) => {
          const {
            id,
            title,
            type,
            createdAt,
            content,
            canSeePost,
            videoDurationInSec,
            CoverImage,
            Profile,
            Tiers,
          } = creatorPost

          const postDate = parseISO(createdAt as string)

          const post = (() => {
            if (!canSeePost && !isProfileOwner) {
              return (
                <CreatorPostCardSubscribe
                  key={id}
                  profileId={Profile.id}
                  name={Profile.name}
                  username={Profile.username}
                  postDate={postDate}
                  profilePictureUrl={Profile.profilePicture?.thumbnailSignedUrl ?? null}
                  Tiers={Tiers}
                  returnTo={getPostHrefUrl(Profile, id)}
                />
              )
            }

            if (type === CreatorPostType.Post) {
              return (
                <CreatorPostCard
                  key={id}
                  hrefUrl={getPostHrefUrl(Profile, id)}
                  postTitle={title}
                  postDate={postDate}
                  name={Profile.name}
                  username={Profile.username}
                  title={title}
                  contentRaw={content || null}
                  profilePictureUrl={Profile.profilePicture?.thumbnailSignedUrl ?? null}
                  postPictureUrl={CoverImage?.thumbnailSignedUrl ?? null}
                />
              )
            }
            if (type === CreatorPostType.Video) {
              return (
                <CreatorPostCardVideo
                  key={id}
                  hrefUrl={getPostHrefUrl(Profile, id)}
                  postTitle={title}
                  postDate={postDate}
                  name={Profile.name}
                  username={Profile.username}
                  title={title}
                  contentRaw={content || null}
                  profilePictureUrl={Profile.profilePicture?.thumbnailSignedUrl ?? null}
                  videoPictureUrl={CoverImage?.thumbnailSignedUrl ?? null}
                  lengthInSec={videoDurationInSec ?? null}
                />
              )
            }

            return null
          })()

          return (
            <HStack key={id}>
              <Box flex="1" css={{ perspective: '500px' }}>
                {post}
              </Box>
              {isProfileOwner && (
                <IconButton
                  icon={<Icon as={BiTrash} w="6" h="6" color="red" />}
                  size="md"
                  flex="none"
                  aria-label={t('common:Shop:delete')}
                  variant="ghost"
                  px="3"
                  onClick={() => setPostToDelete(creatorPost)}
                />
              )}
            </HStack>
          )
        })}
      </VStack>
      {loading ? (
        <>{!creatorPosts?.length && <LoadingGeneral />}</>
      ) : (
        <>
          {!!creatorPosts?.length ? (
            <Flex justifyContent="center" w="full">
              <Pagination
                lastPage={Math.ceil(data!.creatorPosts.pagination.total / DataLimit.products)}
                currentPage={Number.parseInt(page || '1')}
                onGeneratePageLink={(pageNumber) =>
                  `${router.asPath.split('?')[0]}?page=${pageNumber}`
                }
              />
            </Flex>
          ) : (
            <BorderWrapper
              emptyComp={
                <EmptyTableContent
                  ctaButton={
                    <ShareProfileModal
                      url={`${process.env.NEXT_PUBLIC_APP_URL}${getRoute('profile', profile.username)}`}
                    >
                      <Button leftIcon={<Icon as={FiLink} />}>
                        {t('merchant:ProfileInfoCover.shareProfile')}
                      </Button>
                    </ShareProfileModal>
                  }
                  imageWidth={{ base: '35px', md: '58px' }}
                  imageHeight={{ base: '30px', md: '50px' }}
                  imageUrl="/images/empty-pot.svg"
                  imageAlt="empty pot"
                  title={t('CreatorPostsList.noPostsTitle')}
                  description={t('CreatorPostsList.noPostsDescription')}
                />
              }
              isEmpty
            />
          )}
        </>
      )}
      <DeletePostModal post={postToDelete} onClose={() => setPostToDelete(null)} />
    </Box>
  )
}
