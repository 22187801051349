import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { ChakraProvider } from '@chakra-ui/react'
import { init as initFullStory } from '@fullstory/browser'
import { Auth } from 'components/Auth'
import { HeadPwa } from 'components/HeadPwa'
import { Header } from 'components/Header'
import { Mixpanel } from 'components/Mixpanel'
import { parse as parseCookies } from 'cookie'
import { I18nDictionary } from 'next-translate'
import I18nProvider from 'next-translate/I18nProvider'
import loadNamespaces from 'next-translate/loadNamespaces'
import { AppContext } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { StaticImageData } from 'next/legacy/image'
import { useRouter } from 'next/router'
import logo from 'public/favicon.svg'
import React from 'react'
import { langCookieName } from 'src/constants'
import { AppPageProps, AuthPageComponent, ExtendedPageProps } from 'src/types/general'
import chakraTheme from 'theme/index'
import { AppProviders } from 'utils/AppProviders'
import { pageview } from 'utils/gtag'
import { getIsSsr } from 'utils/helpers'
import { i18nConfig } from 'utils/i18n'

const { NEXT_PUBLIC_FULLSTORY_ORG_ID } = process.env

if (!getIsSsr() && NEXT_PUBLIC_FULLSTORY_ORG_ID) {
  initFullStory({
    orgId: NEXT_PUBLIC_FULLSTORY_ORG_ID,
    debug: process.env.NODE_ENV !== 'production',
  })
}

const NanoProgress = dynamic(() => import('src/components/NanoProgress'), { ssr: false })

// TODO: Exit animation, viewport issue
// https://wallis.dev/blog/nextjs-page-transitions-with-framer-motion
// https://github.com/chrishornmem/next-auth-example/blob/master/pages/_app.js

interface Props {
  __lang?: string
  __namespaces?: Record<string, I18nDictionary>
}

const App = ({
  Component,
  pageProps,
  __lang,
  __namespaces,
}: Props & ExtendedPageProps<AppPageProps>) => {
  const router = useRouter()
  React.useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <I18nProvider lang={__lang} namespaces={__namespaces}>
      <Head>
        <HeadPwa />
        <link rel="icon" type="image/svg" href={(logo as StaticImageData).src} />
      </Head>
      <ChakraProvider theme={chakraTheme}>
        {/* @todo */}
        <NanoProgress />
        <AppProviders {...pageProps}>
          <Header />
          <Mixpanel>
            {(Component as AuthPageComponent).auth ? (
              <Auth key={router.pathname}>
                <Component {...pageProps} />
              </Auth>
            ) : (
              <Component {...pageProps} />
            )}
          </Mixpanel>
        </AppProviders>
      </ChakraProvider>
    </I18nProvider>
  )
}

App.getInitialProps = async ({ router, ctx }: AppContext) => {
  const { lang } = router.query as { lang?: string }
  const cookies = ctx.req ? ctx.req.headers.cookie : document.cookie
  const parsedCookies = (cookies ? parseCookies(cookies) : {}) as { [langCookieName]?: string }

  let locale = lang ?? parsedCookies[langCookieName]
  if (!locale || !i18nConfig.locales.includes(locale)) {
    locale = i18nConfig.defaultLocale
  }

  const loadedNamespaces = await loadNamespaces({
    ...i18nConfig,
    pathname: router.pathname,
    locale,
    loaderName: 'getInitialProps',
  })

  return {
    ...loadedNamespaces,
  }
}

export default App
