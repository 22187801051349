import React from 'react'
import { Content } from '@tiptap/core'
import useTranslation from 'next-translate/useTranslation'
import { UseFormReturn } from 'react-hook-form'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { TextEditor, useEditorState } from 'components/TextEditor'

interface Props {
  innerRef?: React.RefObject<HTMLDivElement>
  shopProductForm: UseFormReturn<ShopProductInput, any, undefined>
}

const getInitialContentJson = (content: string | null) => {
  if (!content) {
    return null
  }
  try {
    return JSON.parse(content) as Content
  } catch {
    return null
  }
}

export const NameDescription: React.FC<Props> = ({ shopProductForm }) => {
  const { t } = useTranslation('backOffice')
  const initialDescription = shopProductForm.getValues('description')
  const editor = useEditorState({
    initialContent: getInitialContentJson(initialDescription ?? null),
    placeholder: t('Product.nameDescriptionDescriptionInputPlaceholder'),
    onUpdate: ({ editor }) => {
      shopProductForm.setValue('description', JSON.stringify(editor.getJSON()))
    },
  })

  return (
    <div>
      <InputWrapper
        {...shopProductForm.register('name')}
        label={t('Product.nameDescriptionNameInputLabel')}
        variant="outline"
        maxW="full"
      />
      {editor && (
        <TextEditor editor={editor} label={t('Product.nameDescriptionDescriptionInputLabel')} />
      )}
    </div>
  )
}
