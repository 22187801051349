import React from 'react'
import NextLink from 'next/link'
import {
  chakra,
  Divider,
  VStack,
  Box,
  Button,
  Heading,
  Text,
  useDisclosure,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react'
import { IoIosLock } from 'react-icons/io'
import { BsFillCameraVideoFill } from 'react-icons/bs'
import styled from 'styled-components'
import { ProfilePicture } from './ProfilePicture'
import { CoverPicture } from './CoverPicture'
import { HStack, LinkOverlay } from '@chakra-ui/layout'
import { useTipTapJsonToPlainText } from 'components/TextEditor'
import { format } from 'date-fns'
import blank from 'public/images/blank.png'
import { Product } from '../../generated/graphql-frontend'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { MembershipsTab } from 'components/ProfilePage/tabs'
import useTranslation from 'next-translate/useTranslation'
import { getRoute } from 'utils/helpers'
import CardButton from 'components/CardButton'
import { getColorModeValue } from 'utils/theme/helpers'

interface CreatorPostCardProps {
  username: string
  name: string
  postTitle: string
  hrefUrl: string
  postPictureUrl: string | null
  profilePictureUrl: string | null
  title: string
  contentRaw: string | null
  postDate: Date
}

interface CreatorPostVideoCardProps extends Omit<CreatorPostCardProps, 'postPictureUrl'> {
  videoPictureUrl: string | null
  lengthInSec: number | null
}

interface CreatorPostSubscribeCardProps
  extends Pick<CreatorPostCardProps, 'username' | 'name' | 'profilePictureUrl' | 'postDate'> {
  profileId: string
  Tiers: Array<{
    Product: Pick<Product, 'name'>
  }>
  returnTo?: string
}

const OverlayLink = styled(NextLink)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const getVideoTime = (secs: number) => {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor(secs / 60) % 60
  const seconds = secs % 60

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':')
}

export function CreatorPostCard({
  username,
  name,
  postTitle,
  hrefUrl,
  profilePictureUrl,
  postPictureUrl,
  contentRaw,
  postDate,
}: CreatorPostCardProps) {
  const description = useTipTapJsonToPlainText({ json: contentRaw })
  const color = useColorModeValue('primary.700', 'white')
  return (
    <CardButton>
      <OverlayLink href={hrefUrl} />
      {postPictureUrl && (
        <CoverPicture
          id={`cover-${username}`}
          height={{ base: '150px', md: '200px' }}
          width="100%"
          pictureUrl={postPictureUrl}
          borderRadius="0"
          borderBottomLeftRadius={0}
          borderBottomRightRadius={0}
        />
      )}
      <chakra.div p="4">
        <HStack h="100%">
          <ProfilePicture pictureUrl={profilePictureUrl ?? blank.src} size="xs" />
          <VStack alignItems="flex-start" spacing="0" position="relative" zIndex={2}>
            <NextLink href={getRoute('profile', username)}>
              <Heading
                fontSize={{
                  base: 'sm',
                  md: 'md',
                }}
                color={color}
                textAlign="left"
              >
                {name}
              </Heading>
            </NextLink>
            <Text fontSize="xs" color="gray.400">
              {format(postDate, 'LLL dd hh:mm aaaa')}
            </Text>
          </VStack>
        </HStack>
      </chakra.div>
      <Divider />
      <chakra.div pt="2" p="4">
        <chakra.div display="flex" flexDirection="column" alignItems="flex-start">
          <Heading
            size="md"
            color={color}
          >
            {postTitle}
          </Heading>
          <Box minHeight="2.6rem">
            <Text
              mt="2"
              fontSize={{
                base: 'xs',
                md: 'md',
              }}
              noOfLines={3}
              wordBreak="break-word"
            >
              {description?.slice(0, 150)} {(description?.length ?? 0) > 150 ? '...' : ''}
            </Text>
          </Box>
        </chakra.div>
      </chakra.div>
    </CardButton>
  )
}

export function CreatorPostCardVideo({
  name,
  username,
  postTitle,
  hrefUrl,
  contentRaw,
  profilePictureUrl,
  videoPictureUrl,
  lengthInSec,
  postDate,
}: CreatorPostVideoCardProps) {
  const description = useTipTapJsonToPlainText({ json: contentRaw })
  const color = useColorModeValue('primary.700', 'white')
  return (
    <CardButton>
      <OverlayLink href={hrefUrl} />
      <chakra.div p="4">
        <HStack h="100%">
          <ProfilePicture pictureUrl={profilePictureUrl ?? blank.src} size="xs" />
          <VStack alignItems="flex-start" spacing="0" position="relative" zIndex={2}>
            <NextLink href={getRoute('profile', username)}>
              <Heading
                fontSize={{
                  base: 'sm',
                  md: 'md',
                }}
                color={color}
                textAlign="left"
              >
                {name}
              </Heading>
            </NextLink>
            <Text fontSize="xs" color="gray.400">
              {format(postDate, 'LLL dd hh:mm aaaa')}
            </Text>
          </VStack>
        </HStack>
      </chakra.div>
      <Divider />
      <chakra.div pt="2" p="4">
        <HStack alignItems="flex-start">
          {videoPictureUrl && (
            <chakra.div position="relative">
              <CoverPicture
                id={`cover-${username}`}
                height={{ base: '90px', md: '100px' }}
                width={{ base: '125px', md: '150px' }}
                pictureUrl={videoPictureUrl}
                borderRadius="lg"
              />
              {lengthInSec ? (
                <HStack
                  bg="gray.200"
                  spacing="2"
                  alignItems="center"
                  borderRadius="md"
                  py="1"
                  px="3"
                  position="absolute"
                  bottom="0.25rem"
                  right="0.25rem"
                  __css={{ color: getColorModeValue('gray.700', 'whiteAlpha.800') }}
                >
                  <Icon as={BsFillCameraVideoFill} size={2} color="currentcolor" />
                  <Text fontSize="sm">{getVideoTime(lengthInSec)}</Text>
                </HStack>
              ) : null}
            </chakra.div>
          )}
          <chakra.div display="flex" flexDirection="column" alignItems="flex-start">
            <Heading
              size="md"
              color={color}
              noOfLines={1}
            >
              {postTitle}
            </Heading>
            <Box minHeight="2.6rem">
              <Text fontSize="sm" lineHeight="tall" noOfLines={2}>
                <Text
                  mt="2"
                  fontSize={{
                    base: 'xs',
                    md: 'sm',
                  }}
                  noOfLines={3}
                  wordBreak="break-word"
                >
                  {description?.slice(0, 150)} {(description?.length ?? 0) > 150 ? '...' : ''}
                </Text>
              </Text>
            </Box>
          </chakra.div>
        </HStack>
      </chakra.div>
    </CardButton>
  )
}

export function CreatorPostCardSubscribe({
  name,
  profileId,
  profilePictureUrl,
  postDate,
  Tiers,
  returnTo,
}: CreatorPostSubscribeCardProps) {
  const { t } = useTranslation('creatorPost')
  const color = useColorModeValue('primary.700', 'white')
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <CardButton>
        <LinkOverlay onClick={onOpen} />
        <chakra.div p="4">
          <HStack alignItems="flex-start" h="100%">
            <ProfilePicture pictureUrl={profilePictureUrl ?? blank.src} size="xs" />
            <VStack alignItems="flex-start" spacing="0" position="relative" zIndex={2}>
              <Heading fontSize="md" color={color} textAlign="left">
                {name}
              </Heading>
              <Text fontSize="xs" color="gray.400">
                {format(postDate, 'LLL dd hh:mm aaaa')}
              </Text>
            </VStack>
          </HStack>
        </chakra.div>
        <Divider />
        <VStack alignItems="center" p="6">
          <Icon as={IoIosLock} boxSize="16" color="gray.400" />
          <Box>
            {t('PostCard.thisPostIsAvailableInTiers')}:<br />
            {Tiers.map(({ Product }) => (
              <>
                <span>{Product.name}</span>
                <br />
              </>
            ))}
          </Box>
          <Button mt={3} onClick={() => onOpen()}>
            {t('PostCard.ctaSubscribeNow')}
          </Button>
        </VStack>
      </CardButton>

      <ModalOrDrawer
        onClose={() => onClose()}
        isOpen={isOpen}
        title={t('PostCard.tiersModalTitle')}
      >
        <MembershipsTab profileId={profileId} returnTo={returnTo} />
      </ModalOrDrawer>
    </>
  )
}
