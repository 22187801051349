import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AddToOrderInput = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Int']['input'];
  timeSlot?: InputMaybe<TimeSlotInput>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<ReactSelectInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationFee = {
  __typename?: 'ApplicationFee';
  Profiles: Array<Profile>;
  createdAt: Scalars['DateTime']['output'];
  donation: Scalars['Int']['output'];
  ecommerce: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  subscription: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ApplicationFeeInput = {
  donation: Scalars['Int']['input'];
  ecommerce: Scalars['Int']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  subscription: Scalars['Int']['input'];
};

export type Attachment = {
  __typename?: 'Attachment';
  S3File?: Maybe<S3File>;
};

export type Attribute = {
  __typename?: 'Attribute';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: AttributeTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type AttributeProduct = {
  __typename?: 'AttributeProduct';
  AttributeValue: AttributeValue;
  ProductVariant: PartialProduct;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum AttributeTypeEnum {
  Calendar = 'Calendar',
  Dropdown = 'Dropdown',
  TimeCalendar = 'TimeCalendar'
}

export type AttributeValue = {
  __typename?: 'AttributeValue';
  Attribute: Attribute;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  valueInt?: Maybe<Scalars['Int']['output']>;
  valueStr?: Maybe<Scalars['String']['output']>;
};

export type AttributeVariant = AttributeProduct | AttributeVariantExtend;

export type AttributeVariantExtend = {
  __typename?: 'AttributeVariantExtend';
  variantSelection: Scalars['Boolean']['output'];
};

export type BackofficeUpdateOrderInput = {
  status?: InputMaybe<OrderStatusEnum>;
  trackingUrl?: InputMaybe<Scalars['String']['input']>;
};

export type BankConnection = {
  __typename?: 'BankConnection';
  bankName: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['String']['output'];
  swift: Scalars['String']['output'];
};

export type BankConnectionInput = {
  bankName: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  swift: Scalars['String']['input'];
};

export type BankTransferInfo = {
  __typename?: 'BankTransferInfo';
  bankName: Scalars['String']['output'];
  countryCode: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  swift: Scalars['String']['output'];
  variableSymbol: Scalars['String']['output'];
};

export type Booking = {
  __typename?: 'Booking';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  productVariantId: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
  status: BookingStatus;
  userId: Scalars['ID']['output'];
};

export enum BookingStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Pending = 'Pending'
}

export type BookingTemplateSettings = {
  __typename?: 'BookingTemplateSettings';
  SlotSettings: Array<SlotSettings>;
  daysInAdvance: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  minimumNoticeInMinutes: Scalars['Int']['output'];
  participantsPerSlot: Scalars['Int']['output'];
  slotBreakInMins: Scalars['Int']['output'];
  slotDurationInMins: Scalars['Int']['output'];
};

export type BookingTemplateSettingsInput = {
  SlotSettings: Array<SlotSettingsInput>;
  daysInAdvance: Scalars['Int']['input'];
  isEnabled: Scalars['Boolean']['input'];
  minimumNoticeInMinutes: Scalars['Int']['input'];
  participantsPerSlot: Scalars['Int']['input'];
  slotBreakInMins: Scalars['Int']['input'];
  slotDurationInMins: Scalars['Int']['input'];
};

export type BookingTimeSlot = {
  __typename?: 'BookingTimeSlot';
  ContactInfo?: Maybe<ContactInfo>;
  MerchantProfile?: Maybe<Profile>;
  ProductVariantOrder?: Maybe<ProductVariantOrder>;
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isPaidFor?: Maybe<Scalars['Boolean']['output']>;
  meetingDetails?: Maybe<Scalars['String']['output']>;
  sessionEnd: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
  status: BookingStatus;
};

export enum BookingType {
  OneTime = 'OneTime',
  Recurring = 'Recurring'
}

export type BookingsWithPagination = {
  __typename?: 'BookingsWithPagination';
  bookings: Array<BookingTimeSlot>;
  pagination: Pagination;
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  last4: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CartSummary = {
  __typename?: 'CartSummary';
  MerchantProfile: Profile;
  amountCents: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  itemsCount: Scalars['Int']['output'];
};

export type CheckOrderItemsAvailabilityReturnItem = {
  __typename?: 'CheckOrderItemsAvailabilityReturnItem';
  clientProductName: Scalars['String']['output'];
  inventoryQuantity: Scalars['Int']['output'];
  productVariantId: Scalars['String']['output'];
  productVariantOrderId: Scalars['String']['output'];
};

export type CommunityPost = {
  __typename?: 'CommunityPost';
  CommunityPostAuthor: CommunityPostAuthor;
  commentCount: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  hasCurrentUserLiked: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: CommunityPostType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CommunityPostAuthor = {
  __typename?: 'CommunityPostAuthor';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<S3File>;
};

export type CommunityPostComment = {
  __typename?: 'CommunityPostComment';
  CommunityPostAuthor: CommunityPostAuthor;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasCurrentUserLiked: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  likeCount: Scalars['Int']['output'];
  parentCommunityPostCommentId?: Maybe<Scalars['String']['output']>;
  replies: Array<CommunityPostComment>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CommunityPostCommentInput = {
  communityPostId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  parentCommunityPostCommentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CommunityPostCommentsWithPagination = {
  __typename?: 'CommunityPostCommentsWithPagination';
  communityPostComments: Array<CommunityPostComment>;
  pagination: Pagination;
};

export type CommunityPostInput = {
  content: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: CommunityPostType;
};

export enum CommunityPostType {
  BugReport = 'BugReport',
  FeatureRequest = 'FeatureRequest',
  GeneralDiscussion = 'GeneralDiscussion'
}

export type CommunityPostsWithPagination = {
  __typename?: 'CommunityPostsWithPagination';
  communityPosts: Array<CommunityPost>;
  pagination: Pagination;
};

export enum ConfirmationTypeEnum {
  PasswordReset = 'PasswordReset',
  UpdateEmail = 'UpdateEmail'
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactInfoInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProfileInput = {
  coverPictureKey: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  description: Scalars['String']['input'];
  label: Scalars['String']['input'];
  labelColorScheme: Scalars['String']['input'];
  name: Scalars['String']['input'];
  profilePictureKey: Scalars['String']['input'];
  socialMediaAccounts?: InputMaybe<Array<SocialMediaAccountInput>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWebsiteInput = {
  domain: Scalars['String']['input'];
  profileId: Scalars['ID']['input'];
};

export type CreatorPost = {
  __typename?: 'CreatorPost';
  CoverImage?: Maybe<S3File>;
  Profile: Profile;
  Stream?: Maybe<Stream>;
  Tiers: Array<Tier>;
  Video?: Maybe<S3File>;
  canSeePost: Scalars['Boolean']['output'];
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isWithTier: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: CreatorPostType;
  updatedAt: Scalars['DateTime']['output'];
  videoDurationInSec?: Maybe<Scalars['Int']['output']>;
};

export type CreatorPostInput = {
  content: Scalars['String']['input'];
  coverImageKey?: InputMaybe<Scalars['String']['input']>;
  isWithTier: Scalars['Boolean']['input'];
  profileId: Scalars['ID']['input'];
  tierIds: Array<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  type: CreatorPostType;
  videoDurationInSec?: InputMaybe<Scalars['Int']['input']>;
  videoKey?: InputMaybe<Scalars['String']['input']>;
};

export enum CreatorPostType {
  Post = 'Post',
  Video = 'Video'
}

export type CreatorPostsFilterData = {
  dateBefore: Scalars['DateTime']['input'];
  profileIds: Array<Scalars['ID']['input']>;
};

export type CreatorPostsWithPagination = {
  __typename?: 'CreatorPostsWithPagination';
  creatorPosts: Array<CreatorPost>;
  pagination: Pagination;
};

export type CustomOrderInput = {
  amount: Scalars['Int']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  instantPaymentId?: InputMaybe<Scalars['String']['input']>;
  merchantProfileId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type DeviceInput = {
  browserName?: InputMaybe<Scalars['String']['input']>;
  browserVersion?: InputMaybe<Scalars['String']['input']>;
  browserWindowResolution?: InputMaybe<Array<Scalars['Int']['input']>>;
  deviceModel?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  deviceVendor?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  osName?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  pixelRatio?: InputMaybe<Scalars['Float']['input']>;
  screenResolution?: InputMaybe<Array<Scalars['Int']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export enum FileTypeEnum {
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export type InstantPayment = {
  __typename?: 'InstantPayment';
  MerchantProfile: Profile;
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
};

export type InstantPaymentInput = {
  amount: Scalars['Int']['input'];
  merchantProfileId: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type Membership = {
  __typename?: 'Membership';
  Payments: Array<Payment>;
  Price: Price;
  Product: Product;
  Tier: Tier;
  User: User;
  createdAt: Scalars['DateTime']['output'];
  currentPeriodEnd: Scalars['DateTime']['output'];
  currentPeriodStart: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  subscriptionStatus: SubscriptionStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type MembershipsWithPagination = {
  __typename?: 'MembershipsWithPagination';
  memberships: Array<Membership>;
  pagination: Pagination;
};

export type MerchantStats = {
  __typename?: 'MerchantStats';
  allTime?: Maybe<Stat>;
  last7?: Maybe<Stat>;
  last30?: Maybe<Stat>;
  supporters?: Maybe<Stat>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  addProductAttributeProduct: AttributeProduct;
  addProductAttributeVariant: AttributeVariant;
  addToOrder?: Maybe<Order>;
  approveRejectTimeSlot: Array<BookingTimeSlot>;
  backofficeUpdateOrder: Order;
  cancelSubscription: Scalars['Boolean']['output'];
  checkUsernameAvailability: Scalars['Boolean']['output'];
  completeFreeOrder: PaymentIntentResult;
  completeStripePayment?: Maybe<PaymentIntentResult>;
  completeSubscriptionPayment: Scalars['Boolean']['output'];
  copyVideoToStream: Stream;
  createAccountLink: Scalars['String']['output'];
  createAccountLoginLink: Scalars['String']['output'];
  createApplicationFee: ApplicationFee;
  createBankConnection: BankConnection;
  createCommunityPost?: Maybe<CommunityPost>;
  createCommunityPostComment: CommunityPostComment;
  createCreatorPost?: Maybe<CreatorPost>;
  createCustomOrder: Order;
  createInstantPayment: InstantPayment;
  createPaymentIntent: PaymentIntent;
  createProduct: PartialProduct;
  createProfile?: Maybe<Profile>;
  createShippingZone: ShippingZone;
  createSubscription: SubscriptionData;
  createTier: Tier;
  createWebsite: Scalars['Boolean']['output'];
  deactivateAccount?: Maybe<Scalars['Boolean']['output']>;
  deleteBankConnection: Scalars['Boolean']['output'];
  deleteCommunityPost: Scalars['Boolean']['output'];
  deleteCommunityPostComment: Scalars['Boolean']['output'];
  deleteCreatorPost: Scalars['Boolean']['output'];
  deleteProduct: Scalars['Boolean']['output'];
  deleteShippingZone: Scalars['Boolean']['output'];
  fileUploadS3Presign: S3Payload;
  findMultipleTimeSlotsWithSameOrder: Array<BookingTimeSlot>;
  finishRoleOnboarding: Scalars['Boolean']['output'];
  generateProductVariants: Array<PartialProductVariant>;
  likeCommunityPost?: Maybe<CommunityPost>;
  likeCommunityPostComment?: Maybe<CommunityPostComment>;
  paymentIntentManualApprove: PaymentIntentResult;
  paymentIntentManualCancel: PaymentIntentResult;
  paymentIntentManualConfirm: PaymentIntentResult;
  productVariantFromCombinations?: Maybe<ProductVariant>;
  profileAssignApplicationFee: ApplicationFee;
  reactivateAccount?: Maybe<Scalars['Boolean']['output']>;
  register: Scalars['Boolean']['output'];
  requestEmailUpdate: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  saveDevice: Scalars['Boolean']['output'];
  sendResetPasswordEmail: Scalars['Boolean']['output'];
  swapPushNotificationSubscriptionTokens?: Maybe<PushNotificationSubscription>;
  switchProfile: Scalars['Boolean']['output'];
  togglePushNotificationSubscription?: Maybe<PushNotificationSubscription>;
  triggerExamplePushNotification?: Maybe<Scalars['Boolean']['output']>;
  unlikeCommunityPost?: Maybe<CommunityPost>;
  unlikeCommunityPostComment?: Maybe<CommunityPostComment>;
  unpublishProduct: Scalars['Boolean']['output'];
  updateApplicationFee: ApplicationFee;
  updateBankConnection: BankConnection;
  updateCommunityPost?: Maybe<CommunityPost>;
  updateCommunityPostComment?: Maybe<CommunityPostComment>;
  updateCreatorPost?: Maybe<CreatorPost>;
  updateEmail: Scalars['Boolean']['output'];
  updatePersonalProfile?: Maybe<PersonalProfile>;
  updateProduct: PartialProduct;
  updateProductVariant: PartialProductVariant;
  updateProductVariantOrder: UpdateProductVariantOrderData;
  updateProfile?: Maybe<Profile>;
  updateTimeSlotInfo: BookingTimeSlot;
  updateUserRole: Scalars['Boolean']['output'];
  updateWebsite: Scalars['Boolean']['output'];
  upsertBookingSettings?: Maybe<ProductVariantSlotsSettings>;
  upsertOrderCustomerData: Scalars['Boolean']['output'];
};


export type MutationAddProductAttributeProductArgs = {
  inputData: ProductAttributeInput;
};


export type MutationAddProductAttributeVariantArgs = {
  inputData: ProductAttributeInput;
};


export type MutationAddToOrderArgs = {
  inputData: AddToOrderInput;
};


export type MutationApproveRejectTimeSlotArgs = {
  action: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  timeSlotId: Scalars['ID']['input'];
};


export type MutationBackofficeUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  inputData: BackofficeUpdateOrderInput;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCheckUsernameAvailabilityArgs = {
  username: Scalars['String']['input'];
};


export type MutationCompleteFreeOrderArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationCompleteStripePaymentArgs = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  email: Scalars['String']['input'];
  stripePaymentId: Scalars['String']['input'];
};


export type MutationCompleteSubscriptionPaymentArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type MutationCopyVideoToStreamArgs = {
  creatorPostId: Scalars['ID']['input'];
  s3FileId: Scalars['ID']['input'];
};


export type MutationCreateApplicationFeeArgs = {
  inputData: ApplicationFeeInput;
};


export type MutationCreateBankConnectionArgs = {
  inputData: BankConnectionInput;
};


export type MutationCreateCommunityPostArgs = {
  inputData: CommunityPostInput;
};


export type MutationCreateCommunityPostCommentArgs = {
  inputData: CommunityPostCommentInput;
};


export type MutationCreateCreatorPostArgs = {
  inputData: CreatorPostInput;
};


export type MutationCreateCustomOrderArgs = {
  inputData: CustomOrderInput;
};


export type MutationCreateInstantPaymentArgs = {
  inputData: InstantPaymentInput;
};


export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input'];
  productInput: ProductInput;
  type: PaymentTypeEnum;
};


export type MutationCreateProductArgs = {
  inputData: ShopProductInput;
};


export type MutationCreateProfileArgs = {
  inputData: CreateProfileInput;
};


export type MutationCreateShippingZoneArgs = {
  inputData: ShippingZoneInput;
};


export type MutationCreateSubscriptionArgs = {
  inputData: SubscriptionInput;
};


export type MutationCreateTierArgs = {
  inputData: TierInput;
};


export type MutationCreateWebsiteArgs = {
  inputData: CreateWebsiteInput;
};


export type MutationDeleteBankConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommunityPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCommunityPostCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCreatorPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationDeleteShippingZoneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationFileUploadS3PresignArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  fileType: FileTypeEnum;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationFindMultipleTimeSlotsWithSameOrderArgs = {
  timeSlotId: Scalars['ID']['input'];
};


export type MutationGenerateProductVariantsArgs = {
  options: Array<ShopProductOptionInput>;
  productId: Scalars['ID']['input'];
};


export type MutationLikeCommunityPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLikeCommunityPostCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPaymentIntentManualApproveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPaymentIntentManualCancelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPaymentIntentManualConfirmArgs = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationProductVariantFromCombinationsArgs = {
  combinations: Array<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
};


export type MutationProfileAssignApplicationFeeArgs = {
  applicationFeeId: Scalars['ID']['input'];
  username: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRequestEmailUpdateArgs = {
  email: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSaveDeviceArgs = {
  inputData: DeviceInput;
};


export type MutationSendResetPasswordEmailArgs = {
  emailOrUsername: Scalars['String']['input'];
};


export type MutationSwapPushNotificationSubscriptionTokensArgs = {
  newToken: Scalars['String']['input'];
  oldToken: Scalars['String']['input'];
};


export type MutationSwitchProfileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTogglePushNotificationSubscriptionArgs = {
  isEnabled: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};


export type MutationTriggerExamplePushNotificationArgs = {
  token: Scalars['String']['input'];
};


export type MutationUnlikeCommunityPostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnlikeCommunityPostCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnpublishProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationUpdateApplicationFeeArgs = {
  id: Scalars['ID']['input'];
  inputData: ApplicationFeeInput;
};


export type MutationUpdateBankConnectionArgs = {
  inputData: BankConnectionInput;
};


export type MutationUpdateCommunityPostArgs = {
  id: Scalars['ID']['input'];
  inputData: CommunityPostInput;
};


export type MutationUpdateCommunityPostCommentArgs = {
  id: Scalars['ID']['input'];
  inputData: CommunityPostCommentInput;
};


export type MutationUpdateCreatorPostArgs = {
  id: Scalars['ID']['input'];
  inputData: CreatorPostInput;
};


export type MutationUpdateEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationUpdatePersonalProfileArgs = {
  inputData: PersonalProfileInput;
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID']['input'];
  inputData: ShopProductInput;
};


export type MutationUpdateProductVariantArgs = {
  inputData: ShopProductInput;
  variantId: Scalars['ID']['input'];
};


export type MutationUpdateProductVariantOrderArgs = {
  id: Scalars['ID']['input'];
  inputData: ProductVariantOrderInput;
};


export type MutationUpdateProfileArgs = {
  id: Scalars['ID']['input'];
  inputData: ProfileInput;
};


export type MutationUpdateTimeSlotInfoArgs = {
  field: Scalars['String']['input'];
  timeSlotId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID']['input'];
  role: UserRoleEnum;
};


export type MutationUpdateWebsiteArgs = {
  id: Scalars['ID']['input'];
  inputData: CreateWebsiteInput;
};


export type MutationUpsertBookingSettingsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  productVariantId: Scalars['ID']['input'];
  slotSettings: ProductVariantSlotsSettingsInput;
};


export type MutationUpsertOrderCustomerDataArgs = {
  addressInput?: InputMaybe<AddressInput>;
  contactInfoInput?: InputMaybe<ContactInfoInput>;
  orderId: Scalars['ID']['input'];
};

export type NewOrder = {
  __typename?: 'NewOrder';
  id: Scalars['ID']['output'];
  status: OrderStatusEnum;
};

export type Order = {
  __typename?: 'Order';
  Address?: Maybe<Address>;
  ContactInfo?: Maybe<ContactInfo>;
  MerchantProfile: Profile;
  Payment?: Maybe<Payment>;
  ProductVariantOrders: Array<ProductVariantOrder>;
  Shipping?: Maybe<Shipping>;
  User?: Maybe<User>;
  amount: Scalars['Int']['output'];
  attributeValues: Array<ProductAttributeOrderValueAssignment>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  requiresAddress: Scalars['Boolean']['output'];
  status: OrderStatusEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type OrderMerchantProfileArgs = {
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderByDirectionEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type OrderPaymentIntentError = {
  __typename?: 'OrderPaymentIntentError';
  missingAddress?: Maybe<Scalars['Boolean']['output']>;
  unavailableItems?: Maybe<Array<CheckOrderItemsAvailabilityReturnItem>>;
};

export enum OrderStatusEnum {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PendingPayment = 'PendingPayment',
  Processing = 'Processing',
  Refunded = 'Refunded',
  Shipped = 'Shipped',
  WaitingMerchant = 'WaitingMerchant'
}

export type OrdersFilterData = {
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type OrdersWithPagination = {
  __typename?: 'OrdersWithPagination';
  orders: Array<Order>;
  pagination: Pagination;
};

export type Pagination = {
  __typename?: 'Pagination';
  from: Scalars['Int']['output'];
  length: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationInput = {
  from: Scalars['Int']['input'];
  length: Scalars['Int']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderByDirection?: InputMaybe<OrderByDirectionEnum>;
};

export type PartialProduct = {
  __typename?: 'PartialProduct';
  Attachments?: Maybe<Array<S3File>>;
  AttributeProduct: Array<ShopProductOption>;
  BookingSettings?: Maybe<BookingTemplateSettings>;
  Pictures?: Maybe<Array<S3File>>;
  Price?: Maybe<Price>;
  Product?: Maybe<PartialProduct>;
  Profile: Profile;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasOptions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inventoryQuantity?: Maybe<Scalars['Int']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productVariantsQuantities: Array<ProductVariantQuantity>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  requiresAddress?: Maybe<Scalars['Boolean']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  thankYouMessage?: Maybe<Scalars['String']['output']>;
  trackInventory?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type PartialProductVariant = {
  __typename?: 'PartialProductVariant';
  Pictures?: Maybe<Array<S3File>>;
  Price?: Maybe<Price>;
  Product?: Maybe<PartialProduct>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryQuantity?: Maybe<Scalars['Int']['output']>;
  isEnabled: Scalars['Boolean']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type PartialProductsWithPagination = {
  __typename?: 'PartialProductsWithPagination';
  pagination: Pagination;
  products: Array<PartialProduct>;
};

export type Payment = {
  __typename?: 'Payment';
  MerchantProfile?: Maybe<Profile>;
  Order?: Maybe<Order>;
  User?: Maybe<User>;
  amount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSubscription: Scalars['Boolean']['output'];
  message?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  status: PaymentStatusEnum;
  type: PaymentTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  bankTransferInfo?: Maybe<BankTransferInfo>;
  clientSecret?: Maybe<Scalars['String']['output']>;
};

export type PaymentIntentResult = OrderPaymentIntentError | PaymentResult;

export type PaymentResult = {
  __typename?: 'PaymentResult';
  success: Scalars['Boolean']['output'];
};

export type PaymentShareInfo = {
  __typename?: 'PaymentShareInfo';
  MerchantProfile: Profile;
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isRecurringDonation: Scalars['Boolean']['output'];
};

export enum PaymentStatusEnum {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  Pending = 'Pending',
  Refunded = 'Refunded'
}

export enum PaymentTypeEnum {
  ApplePay = 'ApplePay',
  BankTransfer = 'BankTransfer',
  Card = 'Card',
  Crypto = 'Crypto',
  Custom = 'Custom',
  Free = 'Free',
  GooglePay = 'GooglePay'
}

export type PaymentsWithPagination = {
  __typename?: 'PaymentsWithPagination';
  pagination: Pagination;
  payments: Array<Payment>;
};

export type PersonalProfile = {
  __typename?: 'PersonalProfile';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<S3File>;
  socialMediaAccounts: Array<SocialMediaAccount>;
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type PersonalProfileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  profilePictureKey?: InputMaybe<Scalars['String']['input']>;
  socialMediaAccounts?: InputMaybe<Array<SocialMediaAccountInput>>;
  username?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Price = {
  __typename?: 'Price';
  ShippingZones?: Maybe<Array<ShippingZone>>;
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  requiresAddress?: Maybe<Scalars['Boolean']['output']>;
};

export enum PriceTypeEnum {
  OneTime = 'OneTime',
  Recurring = 'Recurring'
}

export type Product = {
  __typename?: 'Product';
  AttributeProduct: Array<ShopProductOption>;
  Pictures: Array<S3File>;
  Price: Price;
  Profile: Profile;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  hasOptions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductAttributeInput = {
  attributeId: Scalars['String']['input'];
  productTypeId: Scalars['String']['input'];
};

export type ProductAttributeOrderValueAssignment = {
  __typename?: 'ProductAttributeOrderValueAssignment';
  AttributeValue: AttributeValue;
  Order: Order;
};

export type ProductInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  instantPaymentId?: InputMaybe<Scalars['String']['input']>;
  merchantProfileId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type ProductSettings = {
  __typename?: 'ProductSettings';
  BankConnections: Array<BankConnection>;
  buttonColorScheme?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  donationCoverageFee?: Maybe<Scalars['Boolean']['output']>;
  headingText?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPersonalised?: Maybe<Scalars['Boolean']['output']>;
  mainText?: Maybe<Scalars['String']['output']>;
  thankYouMessage?: Maybe<Scalars['String']['output']>;
  widgetColorScheme?: Maybe<Scalars['String']['output']>;
  widgetIsPopup?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductSettingsInput = {
  buttonColorScheme?: InputMaybe<Scalars['String']['input']>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  donationCoverageFee?: InputMaybe<Scalars['Boolean']['input']>;
  headingText?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isPersonalised?: InputMaybe<Scalars['Boolean']['input']>;
  mainText?: InputMaybe<Scalars['String']['input']>;
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  widgetColorScheme?: InputMaybe<Scalars['String']['input']>;
  widgetIsPopup?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  Attributes: Array<Attribute>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isDigital?: Maybe<Scalars['Boolean']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  isShippable?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProductTypeEnum>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductTypeAttributeInput = {
  attrId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type: AttributeTypeEnum;
};

export enum ProductTypeEnum {
  Booking = 'Booking',
  Class = 'Class',
  Digital = 'Digital',
  Download = 'Download',
  MembershipTier = 'MembershipTier',
  Physical = 'Physical',
  Videocall = 'Videocall'
}

export type ProductTypeInput = {
  Attributes: Array<ProductTypeAttributeInput>;
  isDigital: Scalars['Boolean']['input'];
  isShippable: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  type: ProductTypeEnum;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  Attachments?: Maybe<Array<Attachment>>;
  AttributeProduct: Array<ShopProductOption>;
  AttributeVariant: Array<ShopProductOption>;
  BookingSettings?: Maybe<ProductVariantBookingSettings>;
  Pictures: Array<S3File>;
  Price: Price;
  Product: Product;
  Profile: Profile;
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ProductVariantAttachmentsArgs = {
  orderId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductVariantBookingSettings = {
  __typename?: 'ProductVariantBookingSettings';
  daysInAdvance: Scalars['Int']['output'];
  isEnabled: Scalars['Boolean']['output'];
  minimumNoticeInMinutes: Scalars['Int']['output'];
};

export type ProductVariantDate = {
  __typename?: 'ProductVariantDate';
  date: Scalars['DateTime']['output'];
  isAvailable: Scalars['Boolean']['output'];
};

export type ProductVariantOrder = {
  __typename?: 'ProductVariantOrder';
  Order: Order;
  ProductVariant: ProductVariant;
  TimeSlot?: Maybe<BookingTimeSlot>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  productVariantId: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductVariantOrderInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductVariantQuantity = {
  __typename?: 'ProductVariantQuantity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type ProductVariantSlot = {
  __typename?: 'ProductVariantSlot';
  availableCapacity: Scalars['Int']['output'];
  endDate: Scalars['DateTime']['output'];
  isAvailable: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type ProductVariantSlotsSettings = {
  __typename?: 'ProductVariantSlotsSettings';
  breakInMinutes: Scalars['Int']['output'];
  capacity: Scalars['Int']['output'];
  dayInWeek: Scalars['Int']['output'];
  daysInAdvance: Scalars['Int']['output'];
  durationInMinutes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
};

export type ProductVariantSlotsSettingsInput = {
  breakInMinutes: Scalars['Int']['input'];
  capacity: Scalars['Int']['input'];
  dayInWeek: Scalars['Int']['input'];
  daysInAdvance: Scalars['Int']['input'];
  durationInMinutes: Scalars['Int']['input'];
  endDurationInMinutes: Scalars['Int']['input'];
  isEnabled: Scalars['Boolean']['input'];
  startsInMinutes: Scalars['Int']['input'];
};

export type ProductsWithPagination = {
  __typename?: 'ProductsWithPagination';
  pagination: Pagination;
  products: Array<Product>;
};

export type Profile = {
  __typename?: 'Profile';
  ShopSettings: ShopSettings;
  Tiers: Array<Tier>;
  Website?: Maybe<Website>;
  coverPicture?: Maybe<S3File>;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  hasShopProducts: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  labelColorScheme: Scalars['String']['output'];
  name: Scalars['String']['output'];
  productSettings: ProductSettings;
  profilePicture?: Maybe<S3File>;
  socialMediaAccounts: Array<SocialMediaAccount>;
  stripeChargesEnabled?: Maybe<Scalars['Boolean']['output']>;
  stripeConnectId?: Maybe<Scalars['ID']['output']>;
  supportersCount: Scalars['Int']['output'];
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type ProfileInput = {
  coverPictureKey?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  labelColorScheme?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productSettings?: InputMaybe<ProductSettingsInput>;
  profilePictureKey?: InputMaybe<Scalars['String']['input']>;
  socialMediaAccounts?: InputMaybe<Array<SocialMediaAccountInput>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum ProfileTypeEnum {
  Merchant = 'Merchant',
  Personal = 'Personal'
}

export type ProfilesFilterInput = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ProfilesWithPagination = {
  __typename?: 'ProfilesWithPagination';
  pagination: Pagination;
  profiles: Array<Profile>;
};

export type PushNotificationSubscription = {
  __typename?: 'PushNotificationSubscription';
  isEnabled: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  allProducts: Array<SsgProduct>;
  allProfiles: Array<SsgProfile>;
  applicationFees: Array<ApplicationFee>;
  availableTimeSlots: Array<ProductVariantSlot>;
  backofficeOrders: OrdersWithPagination;
  backofficeProduct?: Maybe<PartialProduct>;
  backofficeProductVariants: Array<PartialProductVariant>;
  backofficeProducts: PartialProductsWithPagination;
  booking?: Maybe<Booking>;
  bookings: BookingsWithPagination;
  communityPost?: Maybe<CommunityPost>;
  communityPostComment?: Maybe<CommunityPostComment>;
  communityPostComments: CommunityPostCommentsWithPagination;
  communityPosts: CommunityPostsWithPagination;
  creatorPost?: Maybe<CreatorPost>;
  creatorPosts: CreatorPostsWithPagination;
  currentProfile?: Maybe<Profile>;
  instantPayment: InstantPayment;
  memberships: MembershipsWithPagination;
  merchantStats?: Maybe<MerchantStats>;
  myCards: Array<Card>;
  myCarts: Array<CartSummary>;
  myPayments: PaymentsWithPagination;
  myProfiles: Array<Profile>;
  mySubscriptions: MembershipsWithPagination;
  order?: Maybe<Order>;
  paymentShare: PaymentShareInfo;
  personalProfile?: Maybe<PersonalProfile>;
  product?: Maybe<Product>;
  productTypes: Array<ProductType>;
  productVariant?: Maybe<ProductVariant>;
  products: ProductsWithPagination;
  profile?: Maybe<Profile>;
  profiles?: Maybe<ProfilesWithPagination>;
  pushNotificationSubscription?: Maybe<PushNotificationSubscription>;
  supporters: SupportersWithPagination;
  tier?: Maybe<Tier>;
  tiers: Array<Tier>;
  user?: Maybe<User>;
  websites: WebsitesWithPagination;
};


export type QueryAllProductsArgs = {
  isVariant?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAvailableTimeSlotsArgs = {
  month: Scalars['Int']['input'];
  productVariantId: Scalars['ID']['input'];
  year: Scalars['Int']['input'];
};


export type QueryBackofficeOrdersArgs = {
  filterData?: InputMaybe<OrdersFilterData>;
  pagination: PaginationInput;
};


export type QueryBackofficeProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBackofficeProductVariantsArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryBackofficeProductsArgs = {
  pagination: PaginationInput;
};


export type QueryBookingArgs = {
  timeSlotId: Scalars['ID']['input'];
};


export type QueryBookingsArgs = {
  filter: TimeSlotFilter;
  pagination: PaginationInput;
};


export type QueryCommunityPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommunityPostCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommunityPostCommentsArgs = {
  communityPostId: Scalars['String']['input'];
  pagination: PaginationInput;
};


export type QueryCommunityPostsArgs = {
  pagination: PaginationInput;
};


export type QueryCreatorPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCreatorPostsArgs = {
  filterData: CreatorPostsFilterData;
  pagination: PaginationInput;
};


export type QueryCurrentProfileArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryInstantPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMembershipsArgs = {
  pagination: PaginationInput;
  tierId: Scalars['ID']['input'];
};


export type QueryMerchantStatsArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMyPaymentsArgs = {
  pagination: PaginationInput;
  type: PriceTypeEnum;
};


export type QueryMySubscriptionsArgs = {
  pagination: PaginationInput;
  status: SubscriptionStatusEnum;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPaymentShareArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPersonalProfileArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductVariantArgs = {
  productSlug?: InputMaybe<Scalars['String']['input']>;
  profileUsername?: InputMaybe<Scalars['String']['input']>;
  variantId?: InputMaybe<Scalars['String']['input']>;
  variantSlug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsArgs = {
  filterId?: InputMaybe<Scalars['ID']['input']>;
  pagination: PaginationInput;
  username: Scalars['String']['input'];
};


export type QueryProfileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProfilesArgs = {
  filterData?: InputMaybe<ProfilesFilterInput>;
  pagination: PaginationInput;
};


export type QueryPushNotificationSubscriptionArgs = {
  token: Scalars['String']['input'];
};


export type QuerySupportersArgs = {
  pagination: PaginationInput;
};


export type QueryTierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTiersArgs = {
  includeRecurringDonation?: InputMaybe<Scalars['Boolean']['input']>;
  profileId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWebsitesArgs = {
  inputData?: InputMaybe<WebsitesInput>;
  pagination: PaginationInput;
};

export type ReactSelectInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['String']['input'];
};

export type ReactSelectIntInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Int']['input'];
};

export type S3File = {
  __typename?: 'S3File';
  Uploader: UserPublicDetails;
  createdAt: Scalars['DateTime']['output'];
  encodingStatus?: Maybe<StreamStatus>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  signExpiresAt: Scalars['DateTime']['output'];
  signedUrl: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  thumbnailSignedUrl?: Maybe<Scalars['String']['output']>;
  type: FileTypeEnum;
  updatedAt: Scalars['DateTime']['output'];
};


export type S3FileThumbnailSignedUrlArgs = {
  size: ThumbnailSizeEnum;
};

export type S3Payload = {
  __typename?: 'S3Payload';
  key: Scalars['String']['output'];
  signedRequest: Scalars['String']['output'];
};

export type SsgProduct = {
  __typename?: 'SSGProduct';
  productSlug: Scalars['String']['output'];
  username: Scalars['String']['output'];
  variantSlug?: Maybe<Scalars['String']['output']>;
};

export type SsgProfile = {
  __typename?: 'SSGProfile';
  tabs: Array<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type Shipping = {
  __typename?: 'Shipping';
  ShippingZone?: Maybe<ShippingZone>;
  amount: Scalars['Int']['output'];
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type ShippingZone = {
  __typename?: 'ShippingZone';
  amount: Scalars['Int']['output'];
  countryCodes: Array<Scalars['String']['output']>;
  freeAbove?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isOther?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type ShippingZoneInput = {
  amount: Scalars['Int']['input'];
  countryCodes?: InputMaybe<Array<ReactSelectInput>>;
  freeAbove?: InputMaybe<Scalars['Int']['input']>;
  isOther?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type ShopProductInput = {
  AttachmentsKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  BookingTemplateSettings?: InputMaybe<BookingTemplateSettingsInput>;
  PicturesKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amountCents?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasOptions?: InputMaybe<Scalars['Boolean']['input']>;
  inventoryQuantity?: InputMaybe<Scalars['Int']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  requiresAddress?: InputMaybe<Scalars['Boolean']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  trackInventory?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ShopProductOption = {
  __typename?: 'ShopProductOption';
  attrId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  values: Array<ShopProductOptionValue>;
};

export type ShopProductOptionInput = {
  attrId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  values: Array<ShopProductOptionValueInput>;
};

export type ShopProductOptionValue = {
  __typename?: 'ShopProductOptionValue';
  value?: Maybe<Scalars['String']['output']>;
  valueId: Scalars['String']['output'];
};

export type ShopProductOptionValueInput = {
  value: Scalars['String']['input'];
  valueId: Scalars['String']['input'];
};

export type ShopSettings = {
  __typename?: 'ShopSettings';
  ShippingZones: Array<ShippingZone>;
  id: Scalars['ID']['output'];
};

export type SlotSettings = {
  __typename?: 'SlotSettings';
  dayInWeek: Scalars['Int']['output'];
  fromTime: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  toTime: Scalars['Int']['output'];
};

export type SlotSettingsInput = {
  dayInWeek: Scalars['Int']['input'];
  fromTime: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  toTime: Scalars['Int']['input'];
};

export type SocialMediaAccount = {
  __typename?: 'SocialMediaAccount';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  network: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type SocialMediaAccountInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  network: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Stat = {
  __typename?: 'Stat';
  growth?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export type Stream = {
  __typename?: 'Stream';
  signedUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<StreamStatus>;
  uid: Scalars['ID']['output'];
};

export enum StreamStatus {
  Downloading = 'Downloading',
  Error = 'Error',
  ReadyToStream = 'ReadyToStream'
}

export type Subscription = {
  __typename?: 'Subscription';
  _?: Maybe<Scalars['Boolean']['output']>;
  newOrder?: Maybe<NewOrder>;
  productPurchase: ProductVariantOrder;
};


export type SubscriptionNewOrderArgs = {
  profileId: Scalars['ID']['input'];
  status: Array<OrderStatusEnum>;
};

export type SubscriptionData = {
  __typename?: 'SubscriptionData';
  clientSecret: Scalars['String']['output'];
  subscriptionId: Scalars['ID']['output'];
};

export type SubscriptionInput = {
  amount: Scalars['Int']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  merchantId?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['ID']['input'];
  tierId?: InputMaybe<Scalars['String']['input']>;
};

export enum SubscriptionStatusEnum {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid'
}

export type SupportersWithPagination = {
  __typename?: 'SupportersWithPagination';
  pagination: Pagination;
  supporters: Array<Payment>;
};

export enum ThumbnailSizeEnum {
  CoverPicture = 'CoverPicture',
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export type Tier = {
  __typename?: 'Tier';
  Product: Product;
  amountPerMonth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isRecurringDonation?: Maybe<Scalars['Boolean']['output']>;
  membersCount: Scalars['Int']['output'];
  profileUsername: Scalars['String']['output'];
  welcomeMessage?: Maybe<Scalars['String']['output']>;
};

export type TierInput = {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pictureKey: Scalars['String']['input'];
  profileId?: InputMaybe<Scalars['String']['input']>;
  welcomeMessage: Scalars['String']['input'];
};

export enum TimePeriod {
  Fixed = 'Fixed',
  Past = 'Past',
  Pending = 'Pending',
  Upcoming = 'Upcoming'
}

export type TimeSlotFilter = {
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  period: TimePeriod;
};

export type TimeSlotInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type UpdateProductVariantOrderData = {
  __typename?: 'UpdateProductVariantOrderData';
  isTooBig?: Maybe<Scalars['Boolean']['output']>;
};

export type UpsertOrderContactInfoData = {
  __typename?: 'UpsertOrderContactInfoData';
  address?: Maybe<Address>;
  contactInfo?: Maybe<ContactInfo>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  role: UserRoleEnum;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserPublicDetails = {
  __typename?: 'UserPublicDetails';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum UserRoleEnum {
  SuperAdmin = 'SuperAdmin',
  User = 'User'
}

export type Website = {
  __typename?: 'Website';
  Profile: Profile;
  createdAt: Scalars['DateTime']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WebsitesInput = {
  profileId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type WebsitesWithPagination = {
  __typename?: 'WebsitesWithPagination';
  pagination: Pagination;
  websites: Array<Website>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = T | Promise<T | null> | null;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = ResolversObject<{
  AttributeVariant: ( Partial<AttributeProduct> ) | ( Partial<AttributeVariantExtend> );
  PaymentIntentResult: ( Partial<OrderPaymentIntentError> ) | ( Partial<PaymentResult> );
}>;


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AddToOrderInput: ResolverTypeWrapper<Partial<AddToOrderInput>>;
  Address: ResolverTypeWrapper<Partial<Address>>;
  AddressInput: ResolverTypeWrapper<Partial<AddressInput>>;
  ApplicationFee: ResolverTypeWrapper<Partial<ApplicationFee>>;
  ApplicationFeeInput: ResolverTypeWrapper<Partial<ApplicationFeeInput>>;
  Attachment: ResolverTypeWrapper<Partial<Attachment>>;
  Attribute: ResolverTypeWrapper<Partial<Attribute>>;
  AttributeProduct: ResolverTypeWrapper<Partial<AttributeProduct>>;
  AttributeTypeEnum: ResolverTypeWrapper<Partial<AttributeTypeEnum>>;
  AttributeValue: ResolverTypeWrapper<Partial<AttributeValue>>;
  AttributeVariant: Partial<ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['AttributeVariant']>>;
  AttributeVariantExtend: ResolverTypeWrapper<Partial<AttributeVariantExtend>>;
  BackofficeUpdateOrderInput: ResolverTypeWrapper<Partial<BackofficeUpdateOrderInput>>;
  BankConnection: ResolverTypeWrapper<Partial<BankConnection>>;
  BankConnectionInput: ResolverTypeWrapper<Partial<BankConnectionInput>>;
  BankTransferInfo: ResolverTypeWrapper<Partial<BankTransferInfo>>;
  Booking: ResolverTypeWrapper<Partial<Booking>>;
  BookingStatus: ResolverTypeWrapper<Partial<BookingStatus>>;
  BookingTemplateSettings: ResolverTypeWrapper<Partial<BookingTemplateSettings>>;
  BookingTemplateSettingsInput: ResolverTypeWrapper<Partial<BookingTemplateSettingsInput>>;
  BookingTimeSlot: ResolverTypeWrapper<Partial<BookingTimeSlot>>;
  BookingType: ResolverTypeWrapper<Partial<BookingType>>;
  BookingsWithPagination: ResolverTypeWrapper<Partial<BookingsWithPagination>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']['output']>>;
  Card: ResolverTypeWrapper<Partial<Card>>;
  CartSummary: ResolverTypeWrapper<Partial<CartSummary>>;
  CheckOrderItemsAvailabilityReturnItem: ResolverTypeWrapper<Partial<CheckOrderItemsAvailabilityReturnItem>>;
  CommunityPost: ResolverTypeWrapper<Partial<CommunityPost>>;
  CommunityPostAuthor: ResolverTypeWrapper<Partial<CommunityPostAuthor>>;
  CommunityPostComment: ResolverTypeWrapper<Partial<CommunityPostComment>>;
  CommunityPostCommentInput: ResolverTypeWrapper<Partial<CommunityPostCommentInput>>;
  CommunityPostCommentsWithPagination: ResolverTypeWrapper<Partial<CommunityPostCommentsWithPagination>>;
  CommunityPostInput: ResolverTypeWrapper<Partial<CommunityPostInput>>;
  CommunityPostType: ResolverTypeWrapper<Partial<CommunityPostType>>;
  CommunityPostsWithPagination: ResolverTypeWrapper<Partial<CommunityPostsWithPagination>>;
  ConfirmationTypeEnum: ResolverTypeWrapper<Partial<ConfirmationTypeEnum>>;
  ContactInfo: ResolverTypeWrapper<Partial<ContactInfo>>;
  ContactInfoInput: ResolverTypeWrapper<Partial<ContactInfoInput>>;
  CreateProfileInput: ResolverTypeWrapper<Partial<CreateProfileInput>>;
  CreateWebsiteInput: ResolverTypeWrapper<Partial<CreateWebsiteInput>>;
  CreatorPost: ResolverTypeWrapper<Partial<CreatorPost>>;
  CreatorPostInput: ResolverTypeWrapper<Partial<CreatorPostInput>>;
  CreatorPostType: ResolverTypeWrapper<Partial<CreatorPostType>>;
  CreatorPostsFilterData: ResolverTypeWrapper<Partial<CreatorPostsFilterData>>;
  CreatorPostsWithPagination: ResolverTypeWrapper<Partial<CreatorPostsWithPagination>>;
  CustomOrderInput: ResolverTypeWrapper<Partial<CustomOrderInput>>;
  DateTime: ResolverTypeWrapper<Partial<Scalars['DateTime']['output']>>;
  DeviceInput: ResolverTypeWrapper<Partial<DeviceInput>>;
  FileTypeEnum: ResolverTypeWrapper<Partial<FileTypeEnum>>;
  Float: ResolverTypeWrapper<Partial<Scalars['Float']['output']>>;
  ID: ResolverTypeWrapper<Partial<Scalars['ID']['output']>>;
  InstantPayment: ResolverTypeWrapper<Partial<InstantPayment>>;
  InstantPaymentInput: ResolverTypeWrapper<Partial<InstantPaymentInput>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']['output']>>;
  JSON: ResolverTypeWrapper<Partial<Scalars['JSON']['output']>>;
  JSONObject: ResolverTypeWrapper<Partial<Scalars['JSONObject']['output']>>;
  Membership: ResolverTypeWrapper<Partial<Membership>>;
  MembershipsWithPagination: ResolverTypeWrapper<Partial<MembershipsWithPagination>>;
  MerchantStats: ResolverTypeWrapper<Partial<MerchantStats>>;
  Mutation: ResolverTypeWrapper<{}>;
  NewOrder: ResolverTypeWrapper<Partial<NewOrder>>;
  Order: ResolverTypeWrapper<Partial<Order>>;
  OrderByDirectionEnum: ResolverTypeWrapper<Partial<OrderByDirectionEnum>>;
  OrderPaymentIntentError: ResolverTypeWrapper<Partial<OrderPaymentIntentError>>;
  OrderStatusEnum: ResolverTypeWrapper<Partial<OrderStatusEnum>>;
  OrdersFilterData: ResolverTypeWrapper<Partial<OrdersFilterData>>;
  OrdersWithPagination: ResolverTypeWrapper<Partial<OrdersWithPagination>>;
  Pagination: ResolverTypeWrapper<Partial<Pagination>>;
  PaginationInput: ResolverTypeWrapper<Partial<PaginationInput>>;
  PartialProduct: ResolverTypeWrapper<Partial<PartialProduct>>;
  PartialProductVariant: ResolverTypeWrapper<Partial<PartialProductVariant>>;
  PartialProductsWithPagination: ResolverTypeWrapper<Partial<PartialProductsWithPagination>>;
  Payment: ResolverTypeWrapper<Partial<Payment>>;
  PaymentIntent: ResolverTypeWrapper<Partial<PaymentIntent>>;
  PaymentIntentResult: Partial<ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['PaymentIntentResult']>>;
  PaymentResult: ResolverTypeWrapper<Partial<PaymentResult>>;
  PaymentShareInfo: ResolverTypeWrapper<Partial<PaymentShareInfo>>;
  PaymentStatusEnum: ResolverTypeWrapper<Partial<PaymentStatusEnum>>;
  PaymentTypeEnum: ResolverTypeWrapper<Partial<PaymentTypeEnum>>;
  PaymentsWithPagination: ResolverTypeWrapper<Partial<PaymentsWithPagination>>;
  PersonalProfile: ResolverTypeWrapper<Partial<PersonalProfile>>;
  PersonalProfileInput: ResolverTypeWrapper<Partial<PersonalProfileInput>>;
  Price: ResolverTypeWrapper<Partial<Price>>;
  PriceTypeEnum: ResolverTypeWrapper<Partial<PriceTypeEnum>>;
  Product: ResolverTypeWrapper<Partial<Product>>;
  ProductAttributeInput: ResolverTypeWrapper<Partial<ProductAttributeInput>>;
  ProductAttributeOrderValueAssignment: ResolverTypeWrapper<Partial<ProductAttributeOrderValueAssignment>>;
  ProductInput: ResolverTypeWrapper<Partial<ProductInput>>;
  ProductSettings: ResolverTypeWrapper<Partial<ProductSettings>>;
  ProductSettingsInput: ResolverTypeWrapper<Partial<ProductSettingsInput>>;
  ProductType: ResolverTypeWrapper<Partial<ProductType>>;
  ProductTypeAttributeInput: ResolverTypeWrapper<Partial<ProductTypeAttributeInput>>;
  ProductTypeEnum: ResolverTypeWrapper<Partial<ProductTypeEnum>>;
  ProductTypeInput: ResolverTypeWrapper<Partial<ProductTypeInput>>;
  ProductVariant: ResolverTypeWrapper<Partial<ProductVariant>>;
  ProductVariantBookingSettings: ResolverTypeWrapper<Partial<ProductVariantBookingSettings>>;
  ProductVariantDate: ResolverTypeWrapper<Partial<ProductVariantDate>>;
  ProductVariantOrder: ResolverTypeWrapper<Partial<ProductVariantOrder>>;
  ProductVariantOrderInput: ResolverTypeWrapper<Partial<ProductVariantOrderInput>>;
  ProductVariantQuantity: ResolverTypeWrapper<Partial<ProductVariantQuantity>>;
  ProductVariantSlot: ResolverTypeWrapper<Partial<ProductVariantSlot>>;
  ProductVariantSlotsSettings: ResolverTypeWrapper<Partial<ProductVariantSlotsSettings>>;
  ProductVariantSlotsSettingsInput: ResolverTypeWrapper<Partial<ProductVariantSlotsSettingsInput>>;
  ProductsWithPagination: ResolverTypeWrapper<Partial<ProductsWithPagination>>;
  Profile: ResolverTypeWrapper<Partial<Profile>>;
  ProfileInput: ResolverTypeWrapper<Partial<ProfileInput>>;
  ProfileTypeEnum: ResolverTypeWrapper<Partial<ProfileTypeEnum>>;
  ProfilesFilterInput: ResolverTypeWrapper<Partial<ProfilesFilterInput>>;
  ProfilesWithPagination: ResolverTypeWrapper<Partial<ProfilesWithPagination>>;
  PushNotificationSubscription: ResolverTypeWrapper<Partial<PushNotificationSubscription>>;
  Query: ResolverTypeWrapper<{}>;
  ReactSelectInput: ResolverTypeWrapper<Partial<ReactSelectInput>>;
  ReactSelectIntInput: ResolverTypeWrapper<Partial<ReactSelectIntInput>>;
  S3File: ResolverTypeWrapper<Partial<S3File>>;
  S3Payload: ResolverTypeWrapper<Partial<S3Payload>>;
  SSGProduct: ResolverTypeWrapper<Partial<SsgProduct>>;
  SSGProfile: ResolverTypeWrapper<Partial<SsgProfile>>;
  Shipping: ResolverTypeWrapper<Partial<Shipping>>;
  ShippingZone: ResolverTypeWrapper<Partial<ShippingZone>>;
  ShippingZoneInput: ResolverTypeWrapper<Partial<ShippingZoneInput>>;
  ShopProductInput: ResolverTypeWrapper<Partial<ShopProductInput>>;
  ShopProductOption: ResolverTypeWrapper<Partial<ShopProductOption>>;
  ShopProductOptionInput: ResolverTypeWrapper<Partial<ShopProductOptionInput>>;
  ShopProductOptionValue: ResolverTypeWrapper<Partial<ShopProductOptionValue>>;
  ShopProductOptionValueInput: ResolverTypeWrapper<Partial<ShopProductOptionValueInput>>;
  ShopSettings: ResolverTypeWrapper<Partial<ShopSettings>>;
  SlotSettings: ResolverTypeWrapper<Partial<SlotSettings>>;
  SlotSettingsInput: ResolverTypeWrapper<Partial<SlotSettingsInput>>;
  SocialMediaAccount: ResolverTypeWrapper<Partial<SocialMediaAccount>>;
  SocialMediaAccountInput: ResolverTypeWrapper<Partial<SocialMediaAccountInput>>;
  Stat: ResolverTypeWrapper<Partial<Stat>>;
  Stream: ResolverTypeWrapper<Partial<Stream>>;
  StreamStatus: ResolverTypeWrapper<Partial<StreamStatus>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']['output']>>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionData: ResolverTypeWrapper<Partial<SubscriptionData>>;
  SubscriptionInput: ResolverTypeWrapper<Partial<SubscriptionInput>>;
  SubscriptionStatusEnum: ResolverTypeWrapper<Partial<SubscriptionStatusEnum>>;
  SupportersWithPagination: ResolverTypeWrapper<Partial<SupportersWithPagination>>;
  ThumbnailSizeEnum: ResolverTypeWrapper<Partial<ThumbnailSizeEnum>>;
  Tier: ResolverTypeWrapper<Partial<Tier>>;
  TierInput: ResolverTypeWrapper<Partial<TierInput>>;
  TimePeriod: ResolverTypeWrapper<Partial<TimePeriod>>;
  TimeSlotFilter: ResolverTypeWrapper<Partial<TimeSlotFilter>>;
  TimeSlotInput: ResolverTypeWrapper<Partial<TimeSlotInput>>;
  UpdateProductVariantOrderData: ResolverTypeWrapper<Partial<UpdateProductVariantOrderData>>;
  UpsertOrderContactInfoData: ResolverTypeWrapper<Partial<UpsertOrderContactInfoData>>;
  User: ResolverTypeWrapper<Partial<User>>;
  UserPublicDetails: ResolverTypeWrapper<Partial<UserPublicDetails>>;
  UserRoleEnum: ResolverTypeWrapper<Partial<UserRoleEnum>>;
  Website: ResolverTypeWrapper<Partial<Website>>;
  WebsitesInput: ResolverTypeWrapper<Partial<WebsitesInput>>;
  WebsitesWithPagination: ResolverTypeWrapper<Partial<WebsitesWithPagination>>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AddToOrderInput: Partial<AddToOrderInput>;
  Address: Partial<Address>;
  AddressInput: Partial<AddressInput>;
  ApplicationFee: Partial<ApplicationFee>;
  ApplicationFeeInput: Partial<ApplicationFeeInput>;
  Attachment: Partial<Attachment>;
  Attribute: Partial<Attribute>;
  AttributeProduct: Partial<AttributeProduct>;
  AttributeValue: Partial<AttributeValue>;
  AttributeVariant: Partial<ResolversUnionTypes<ResolversParentTypes>['AttributeVariant']>;
  AttributeVariantExtend: Partial<AttributeVariantExtend>;
  BackofficeUpdateOrderInput: Partial<BackofficeUpdateOrderInput>;
  BankConnection: Partial<BankConnection>;
  BankConnectionInput: Partial<BankConnectionInput>;
  BankTransferInfo: Partial<BankTransferInfo>;
  Booking: Partial<Booking>;
  BookingTemplateSettings: Partial<BookingTemplateSettings>;
  BookingTemplateSettingsInput: Partial<BookingTemplateSettingsInput>;
  BookingTimeSlot: Partial<BookingTimeSlot>;
  BookingsWithPagination: Partial<BookingsWithPagination>;
  Boolean: Partial<Scalars['Boolean']['output']>;
  Card: Partial<Card>;
  CartSummary: Partial<CartSummary>;
  CheckOrderItemsAvailabilityReturnItem: Partial<CheckOrderItemsAvailabilityReturnItem>;
  CommunityPost: Partial<CommunityPost>;
  CommunityPostAuthor: Partial<CommunityPostAuthor>;
  CommunityPostComment: Partial<CommunityPostComment>;
  CommunityPostCommentInput: Partial<CommunityPostCommentInput>;
  CommunityPostCommentsWithPagination: Partial<CommunityPostCommentsWithPagination>;
  CommunityPostInput: Partial<CommunityPostInput>;
  CommunityPostsWithPagination: Partial<CommunityPostsWithPagination>;
  ContactInfo: Partial<ContactInfo>;
  ContactInfoInput: Partial<ContactInfoInput>;
  CreateProfileInput: Partial<CreateProfileInput>;
  CreateWebsiteInput: Partial<CreateWebsiteInput>;
  CreatorPost: Partial<CreatorPost>;
  CreatorPostInput: Partial<CreatorPostInput>;
  CreatorPostsFilterData: Partial<CreatorPostsFilterData>;
  CreatorPostsWithPagination: Partial<CreatorPostsWithPagination>;
  CustomOrderInput: Partial<CustomOrderInput>;
  DateTime: Partial<Scalars['DateTime']['output']>;
  DeviceInput: Partial<DeviceInput>;
  Float: Partial<Scalars['Float']['output']>;
  ID: Partial<Scalars['ID']['output']>;
  InstantPayment: Partial<InstantPayment>;
  InstantPaymentInput: Partial<InstantPaymentInput>;
  Int: Partial<Scalars['Int']['output']>;
  JSON: Partial<Scalars['JSON']['output']>;
  JSONObject: Partial<Scalars['JSONObject']['output']>;
  Membership: Partial<Membership>;
  MembershipsWithPagination: Partial<MembershipsWithPagination>;
  MerchantStats: Partial<MerchantStats>;
  Mutation: {};
  NewOrder: Partial<NewOrder>;
  Order: Partial<Order>;
  OrderPaymentIntentError: Partial<OrderPaymentIntentError>;
  OrdersFilterData: Partial<OrdersFilterData>;
  OrdersWithPagination: Partial<OrdersWithPagination>;
  Pagination: Partial<Pagination>;
  PaginationInput: Partial<PaginationInput>;
  PartialProduct: Partial<PartialProduct>;
  PartialProductVariant: Partial<PartialProductVariant>;
  PartialProductsWithPagination: Partial<PartialProductsWithPagination>;
  Payment: Partial<Payment>;
  PaymentIntent: Partial<PaymentIntent>;
  PaymentIntentResult: Partial<ResolversUnionTypes<ResolversParentTypes>['PaymentIntentResult']>;
  PaymentResult: Partial<PaymentResult>;
  PaymentShareInfo: Partial<PaymentShareInfo>;
  PaymentsWithPagination: Partial<PaymentsWithPagination>;
  PersonalProfile: Partial<PersonalProfile>;
  PersonalProfileInput: Partial<PersonalProfileInput>;
  Price: Partial<Price>;
  Product: Partial<Product>;
  ProductAttributeInput: Partial<ProductAttributeInput>;
  ProductAttributeOrderValueAssignment: Partial<ProductAttributeOrderValueAssignment>;
  ProductInput: Partial<ProductInput>;
  ProductSettings: Partial<ProductSettings>;
  ProductSettingsInput: Partial<ProductSettingsInput>;
  ProductType: Partial<ProductType>;
  ProductTypeAttributeInput: Partial<ProductTypeAttributeInput>;
  ProductTypeInput: Partial<ProductTypeInput>;
  ProductVariant: Partial<ProductVariant>;
  ProductVariantBookingSettings: Partial<ProductVariantBookingSettings>;
  ProductVariantDate: Partial<ProductVariantDate>;
  ProductVariantOrder: Partial<ProductVariantOrder>;
  ProductVariantOrderInput: Partial<ProductVariantOrderInput>;
  ProductVariantQuantity: Partial<ProductVariantQuantity>;
  ProductVariantSlot: Partial<ProductVariantSlot>;
  ProductVariantSlotsSettings: Partial<ProductVariantSlotsSettings>;
  ProductVariantSlotsSettingsInput: Partial<ProductVariantSlotsSettingsInput>;
  ProductsWithPagination: Partial<ProductsWithPagination>;
  Profile: Partial<Profile>;
  ProfileInput: Partial<ProfileInput>;
  ProfilesFilterInput: Partial<ProfilesFilterInput>;
  ProfilesWithPagination: Partial<ProfilesWithPagination>;
  PushNotificationSubscription: Partial<PushNotificationSubscription>;
  Query: {};
  ReactSelectInput: Partial<ReactSelectInput>;
  ReactSelectIntInput: Partial<ReactSelectIntInput>;
  S3File: Partial<S3File>;
  S3Payload: Partial<S3Payload>;
  SSGProduct: Partial<SsgProduct>;
  SSGProfile: Partial<SsgProfile>;
  Shipping: Partial<Shipping>;
  ShippingZone: Partial<ShippingZone>;
  ShippingZoneInput: Partial<ShippingZoneInput>;
  ShopProductInput: Partial<ShopProductInput>;
  ShopProductOption: Partial<ShopProductOption>;
  ShopProductOptionInput: Partial<ShopProductOptionInput>;
  ShopProductOptionValue: Partial<ShopProductOptionValue>;
  ShopProductOptionValueInput: Partial<ShopProductOptionValueInput>;
  ShopSettings: Partial<ShopSettings>;
  SlotSettings: Partial<SlotSettings>;
  SlotSettingsInput: Partial<SlotSettingsInput>;
  SocialMediaAccount: Partial<SocialMediaAccount>;
  SocialMediaAccountInput: Partial<SocialMediaAccountInput>;
  Stat: Partial<Stat>;
  Stream: Partial<Stream>;
  String: Partial<Scalars['String']['output']>;
  Subscription: {};
  SubscriptionData: Partial<SubscriptionData>;
  SubscriptionInput: Partial<SubscriptionInput>;
  SupportersWithPagination: Partial<SupportersWithPagination>;
  Tier: Partial<Tier>;
  TierInput: Partial<TierInput>;
  TimeSlotFilter: Partial<TimeSlotFilter>;
  TimeSlotInput: Partial<TimeSlotInput>;
  UpdateProductVariantOrderData: Partial<UpdateProductVariantOrderData>;
  UpsertOrderContactInfoData: Partial<UpsertOrderContactInfoData>;
  User: Partial<User>;
  UserPublicDetails: Partial<UserPublicDetails>;
  Website: Partial<Website>;
  WebsitesInput: Partial<WebsitesInput>;
  WebsitesWithPagination: Partial<WebsitesWithPagination>;
}>;

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = ResolversObject<{
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  line1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  line2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ApplicationFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationFee'] = ResolversParentTypes['ApplicationFee']> = ResolversObject<{
  Profiles?: Resolver<Array<ResolversTypes['Profile']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  donation?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ecommerce?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscription?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = ResolversObject<{
  S3File?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttributeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attribute'] = ResolversParentTypes['Attribute']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AttributeTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttributeProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeProduct'] = ResolversParentTypes['AttributeProduct']> = ResolversObject<{
  AttributeValue?: Resolver<ResolversTypes['AttributeValue'], ParentType, ContextType>;
  ProductVariant?: Resolver<ResolversTypes['PartialProduct'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttributeValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeValue'] = ResolversParentTypes['AttributeValue']> = ResolversObject<{
  Attribute?: Resolver<ResolversTypes['Attribute'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  valueInt?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  valueStr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttributeVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeVariant'] = ResolversParentTypes['AttributeVariant']> = ResolversObject<{
  __resolveType: TypeResolveFn<'AttributeProduct' | 'AttributeVariantExtend', ParentType, ContextType>;
}>;

export type AttributeVariantExtendResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttributeVariantExtend'] = ResolversParentTypes['AttributeVariantExtend']> = ResolversObject<{
  variantSelection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankConnection'] = ResolversParentTypes['BankConnection']> = ResolversObject<{
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iban?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  swift?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BankTransferInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['BankTransferInfo'] = ResolversParentTypes['BankTransferInfo']> = ResolversObject<{
  bankName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iban?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  swift?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  variableSymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booking'] = ResolversParentTypes['Booking']> = ResolversObject<{
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BookingStatus'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BookingTemplateSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingTemplateSettings'] = ResolversParentTypes['BookingTemplateSettings']> = ResolversObject<{
  SlotSettings?: Resolver<Array<ResolversTypes['SlotSettings']>, ParentType, ContextType>;
  daysInAdvance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minimumNoticeInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  participantsPerSlot?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slotBreakInMins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slotDurationInMins?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BookingTimeSlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingTimeSlot'] = ResolversParentTypes['BookingTimeSlot']> = ResolversObject<{
  ContactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  MerchantProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  ProductVariantOrder?: Resolver<Maybe<ResolversTypes['ProductVariantOrder']>, ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPaidFor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meetingDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sessionEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['BookingStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BookingsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingsWithPagination'] = ResolversParentTypes['BookingsWithPagination']> = ResolversObject<{
  bookings?: Resolver<Array<ResolversTypes['BookingTimeSlot']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']> = ResolversObject<{
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  last4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CartSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['CartSummary'] = ResolversParentTypes['CartSummary']> = ResolversObject<{
  MerchantProfile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  amountCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheckOrderItemsAvailabilityReturnItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckOrderItemsAvailabilityReturnItem'] = ResolversParentTypes['CheckOrderItemsAvailabilityReturnItem']> = ResolversObject<{
  clientProductName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inventoryQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productVariantOrderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPost'] = ResolversParentTypes['CommunityPost']> = ResolversObject<{
  CommunityPostAuthor?: Resolver<ResolversTypes['CommunityPostAuthor'], ParentType, ContextType>;
  commentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  hasCurrentUserLiked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CommunityPostType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostAuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostAuthor'] = ResolversParentTypes['CommunityPostAuthor']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostComment'] = ResolversParentTypes['CommunityPostComment']> = ResolversObject<{
  CommunityPostAuthor?: Resolver<ResolversTypes['CommunityPostAuthor'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasCurrentUserLiked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  parentCommunityPostCommentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replies?: Resolver<Array<ResolversTypes['CommunityPostComment']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostCommentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostCommentsWithPagination'] = ResolversParentTypes['CommunityPostCommentsWithPagination']> = ResolversObject<{
  communityPostComments?: Resolver<Array<ResolversTypes['CommunityPostComment']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostsWithPagination'] = ResolversParentTypes['CommunityPostsWithPagination']> = ResolversObject<{
  communityPosts?: Resolver<Array<ResolversTypes['CommunityPost']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactInfo'] = ResolversParentTypes['ContactInfo']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatorPost'] = ResolversParentTypes['CreatorPost']> = ResolversObject<{
  CoverImage?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  Profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  Stream?: Resolver<Maybe<ResolversTypes['Stream']>, ParentType, ContextType>;
  Tiers?: Resolver<Array<ResolversTypes['Tier']>, ParentType, ContextType>;
  Video?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  canSeePost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isWithTier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CreatorPostType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  videoDurationInSec?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreatorPostsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatorPostsWithPagination'] = ResolversParentTypes['CreatorPostsWithPagination']> = ResolversObject<{
  creatorPosts?: Resolver<Array<ResolversTypes['CreatorPost']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type InstantPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstantPayment'] = ResolversParentTypes['InstantPayment']> = ResolversObject<{
  MerchantProfile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type MembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = ResolversObject<{
  Payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  Price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  Tier?: Resolver<ResolversTypes['Tier'], ParentType, ContextType>;
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currentPeriodEnd?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currentPeriodStart?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  subscriptionStatus?: Resolver<ResolversTypes['SubscriptionStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MembershipsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MembershipsWithPagination'] = ResolversParentTypes['MembershipsWithPagination']> = ResolversObject<{
  memberships?: Resolver<Array<ResolversTypes['Membership']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MerchantStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MerchantStats'] = ResolversParentTypes['MerchantStats']> = ResolversObject<{
  allTime?: Resolver<Maybe<ResolversTypes['Stat']>, ParentType, ContextType>;
  last7?: Resolver<Maybe<ResolversTypes['Stat']>, ParentType, ContextType>;
  last30?: Resolver<Maybe<ResolversTypes['Stat']>, ParentType, ContextType>;
  supporters?: Resolver<Maybe<ResolversTypes['Stat']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  addProductAttributeProduct?: Resolver<ResolversTypes['AttributeProduct'], ParentType, ContextType, RequireFields<MutationAddProductAttributeProductArgs, 'inputData'>>;
  addProductAttributeVariant?: Resolver<ResolversTypes['AttributeVariant'], ParentType, ContextType, RequireFields<MutationAddProductAttributeVariantArgs, 'inputData'>>;
  addToOrder?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, RequireFields<MutationAddToOrderArgs, 'inputData'>>;
  approveRejectTimeSlot?: Resolver<Array<ResolversTypes['BookingTimeSlot']>, ParentType, ContextType, RequireFields<MutationApproveRejectTimeSlotArgs, 'action' | 'timeSlotId'>>;
  backofficeUpdateOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationBackofficeUpdateOrderArgs, 'id' | 'inputData'>>;
  cancelSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelSubscriptionArgs, 'id'>>;
  checkUsernameAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCheckUsernameAvailabilityArgs, 'username'>>;
  completeFreeOrder?: Resolver<ResolversTypes['PaymentIntentResult'], ParentType, ContextType, RequireFields<MutationCompleteFreeOrderArgs, 'orderId'>>;
  completeStripePayment?: Resolver<Maybe<ResolversTypes['PaymentIntentResult']>, ParentType, ContextType, RequireFields<MutationCompleteStripePaymentArgs, 'email' | 'stripePaymentId'>>;
  completeSubscriptionPayment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCompleteSubscriptionPaymentArgs, 'subscriptionId'>>;
  copyVideoToStream?: Resolver<ResolversTypes['Stream'], ParentType, ContextType, RequireFields<MutationCopyVideoToStreamArgs, 'creatorPostId' | 's3FileId'>>;
  createAccountLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createAccountLoginLink?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createApplicationFee?: Resolver<ResolversTypes['ApplicationFee'], ParentType, ContextType, RequireFields<MutationCreateApplicationFeeArgs, 'inputData'>>;
  createBankConnection?: Resolver<ResolversTypes['BankConnection'], ParentType, ContextType, RequireFields<MutationCreateBankConnectionArgs, 'inputData'>>;
  createCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationCreateCommunityPostArgs, 'inputData'>>;
  createCommunityPostComment?: Resolver<ResolversTypes['CommunityPostComment'], ParentType, ContextType, RequireFields<MutationCreateCommunityPostCommentArgs, 'inputData'>>;
  createCreatorPost?: Resolver<Maybe<ResolversTypes['CreatorPost']>, ParentType, ContextType, RequireFields<MutationCreateCreatorPostArgs, 'inputData'>>;
  createCustomOrder?: Resolver<ResolversTypes['Order'], ParentType, ContextType, RequireFields<MutationCreateCustomOrderArgs, 'inputData'>>;
  createInstantPayment?: Resolver<ResolversTypes['InstantPayment'], ParentType, ContextType, RequireFields<MutationCreateInstantPaymentArgs, 'inputData'>>;
  createPaymentIntent?: Resolver<ResolversTypes['PaymentIntent'], ParentType, ContextType, RequireFields<MutationCreatePaymentIntentArgs, 'amount' | 'productInput' | 'type'>>;
  createProduct?: Resolver<ResolversTypes['PartialProduct'], ParentType, ContextType, RequireFields<MutationCreateProductArgs, 'inputData'>>;
  createProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType, RequireFields<MutationCreateProfileArgs, 'inputData'>>;
  createShippingZone?: Resolver<ResolversTypes['ShippingZone'], ParentType, ContextType, RequireFields<MutationCreateShippingZoneArgs, 'inputData'>>;
  createSubscription?: Resolver<ResolversTypes['SubscriptionData'], ParentType, ContextType, RequireFields<MutationCreateSubscriptionArgs, 'inputData'>>;
  createTier?: Resolver<ResolversTypes['Tier'], ParentType, ContextType, RequireFields<MutationCreateTierArgs, 'inputData'>>;
  createWebsite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateWebsiteArgs, 'inputData'>>;
  deactivateAccount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deleteBankConnection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteBankConnectionArgs, 'id'>>;
  deleteCommunityPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCommunityPostArgs, 'id'>>;
  deleteCommunityPostComment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCommunityPostCommentArgs, 'id'>>;
  deleteCreatorPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCreatorPostArgs, 'id'>>;
  deleteProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteProductArgs, 'productId'>>;
  deleteShippingZone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteShippingZoneArgs, 'id'>>;
  fileUploadS3Presign?: Resolver<ResolversTypes['S3Payload'], ParentType, ContextType, RequireFields<MutationFileUploadS3PresignArgs, 'contentType' | 'fileName' | 'fileSize' | 'fileType'>>;
  findMultipleTimeSlotsWithSameOrder?: Resolver<Array<ResolversTypes['BookingTimeSlot']>, ParentType, ContextType, RequireFields<MutationFindMultipleTimeSlotsWithSameOrderArgs, 'timeSlotId'>>;
  finishRoleOnboarding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  generateProductVariants?: Resolver<Array<ResolversTypes['PartialProductVariant']>, ParentType, ContextType, RequireFields<MutationGenerateProductVariantsArgs, 'options' | 'productId'>>;
  likeCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationLikeCommunityPostArgs, 'id'>>;
  likeCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationLikeCommunityPostCommentArgs, 'id'>>;
  paymentIntentManualApprove?: Resolver<ResolversTypes['PaymentIntentResult'], ParentType, ContextType, RequireFields<MutationPaymentIntentManualApproveArgs, 'id'>>;
  paymentIntentManualCancel?: Resolver<ResolversTypes['PaymentIntentResult'], ParentType, ContextType, RequireFields<MutationPaymentIntentManualCancelArgs, 'id'>>;
  paymentIntentManualConfirm?: Resolver<ResolversTypes['PaymentIntentResult'], ParentType, ContextType, RequireFields<MutationPaymentIntentManualConfirmArgs, 'email' | 'id'>>;
  productVariantFromCombinations?: Resolver<Maybe<ResolversTypes['ProductVariant']>, ParentType, ContextType, RequireFields<MutationProductVariantFromCombinationsArgs, 'combinations' | 'productId'>>;
  profileAssignApplicationFee?: Resolver<ResolversTypes['ApplicationFee'], ParentType, ContextType, RequireFields<MutationProfileAssignApplicationFeeArgs, 'applicationFeeId' | 'username'>>;
  reactivateAccount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  register?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRegisterArgs, 'email' | 'password'>>;
  requestEmailUpdate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestEmailUpdateArgs, 'email'>>;
  resetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'password' | 'token'>>;
  saveDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSaveDeviceArgs, 'inputData'>>;
  sendResetPasswordEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendResetPasswordEmailArgs, 'emailOrUsername'>>;
  swapPushNotificationSubscriptionTokens?: Resolver<Maybe<ResolversTypes['PushNotificationSubscription']>, ParentType, ContextType, RequireFields<MutationSwapPushNotificationSubscriptionTokensArgs, 'newToken' | 'oldToken'>>;
  switchProfile?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSwitchProfileArgs, 'id'>>;
  togglePushNotificationSubscription?: Resolver<Maybe<ResolversTypes['PushNotificationSubscription']>, ParentType, ContextType, RequireFields<MutationTogglePushNotificationSubscriptionArgs, 'isEnabled' | 'token'>>;
  triggerExamplePushNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationTriggerExamplePushNotificationArgs, 'token'>>;
  unlikeCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationUnlikeCommunityPostArgs, 'id'>>;
  unlikeCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationUnlikeCommunityPostCommentArgs, 'id'>>;
  unpublishProduct?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUnpublishProductArgs, 'productId'>>;
  updateApplicationFee?: Resolver<ResolversTypes['ApplicationFee'], ParentType, ContextType, RequireFields<MutationUpdateApplicationFeeArgs, 'id' | 'inputData'>>;
  updateBankConnection?: Resolver<ResolversTypes['BankConnection'], ParentType, ContextType, RequireFields<MutationUpdateBankConnectionArgs, 'inputData'>>;
  updateCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationUpdateCommunityPostArgs, 'id' | 'inputData'>>;
  updateCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationUpdateCommunityPostCommentArgs, 'id' | 'inputData'>>;
  updateCreatorPost?: Resolver<Maybe<ResolversTypes['CreatorPost']>, ParentType, ContextType, RequireFields<MutationUpdateCreatorPostArgs, 'id' | 'inputData'>>;
  updateEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateEmailArgs, 'token'>>;
  updatePersonalProfile?: Resolver<Maybe<ResolversTypes['PersonalProfile']>, ParentType, ContextType, RequireFields<MutationUpdatePersonalProfileArgs, 'inputData'>>;
  updateProduct?: Resolver<ResolversTypes['PartialProduct'], ParentType, ContextType, RequireFields<MutationUpdateProductArgs, 'id' | 'inputData'>>;
  updateProductVariant?: Resolver<ResolversTypes['PartialProductVariant'], ParentType, ContextType, RequireFields<MutationUpdateProductVariantArgs, 'inputData' | 'variantId'>>;
  updateProductVariantOrder?: Resolver<ResolversTypes['UpdateProductVariantOrderData'], ParentType, ContextType, RequireFields<MutationUpdateProductVariantOrderArgs, 'id' | 'inputData'>>;
  updateProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'id' | 'inputData'>>;
  updateTimeSlotInfo?: Resolver<ResolversTypes['BookingTimeSlot'], ParentType, ContextType, RequireFields<MutationUpdateTimeSlotInfoArgs, 'field' | 'timeSlotId' | 'value'>>;
  updateUserRole?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateUserRoleArgs, 'id' | 'role'>>;
  updateWebsite?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateWebsiteArgs, 'id' | 'inputData'>>;
  upsertBookingSettings?: Resolver<Maybe<ResolversTypes['ProductVariantSlotsSettings']>, ParentType, ContextType, RequireFields<MutationUpsertBookingSettingsArgs, 'productVariantId' | 'slotSettings'>>;
  upsertOrderCustomerData?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpsertOrderCustomerDataArgs, 'orderId'>>;
}>;

export type NewOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewOrder'] = ResolversParentTypes['NewOrder']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatusEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Order'] = ResolversParentTypes['Order']> = ResolversObject<{
  Address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  ContactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  MerchantProfile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType, Partial<OrderMerchantProfileArgs>>;
  Payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  ProductVariantOrders?: Resolver<Array<ResolversTypes['ProductVariantOrder']>, ParentType, ContextType>;
  Shipping?: Resolver<Maybe<ResolversTypes['Shipping']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  attributeValues?: Resolver<Array<ResolversTypes['ProductAttributeOrderValueAssignment']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  requiresAddress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrderStatusEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrderPaymentIntentErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrderPaymentIntentError'] = ResolversParentTypes['OrderPaymentIntentError']> = ResolversObject<{
  missingAddress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unavailableItems?: Resolver<Maybe<Array<ResolversTypes['CheckOrderItemsAvailabilityReturnItem']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrdersWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrdersWithPagination'] = ResolversParentTypes['OrdersWithPagination']> = ResolversObject<{
  orders?: Resolver<Array<ResolversTypes['Order']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']> = ResolversObject<{
  from?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartialProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialProduct'] = ResolversParentTypes['PartialProduct']> = ResolversObject<{
  Attachments?: Resolver<Maybe<Array<ResolversTypes['S3File']>>, ParentType, ContextType>;
  AttributeProduct?: Resolver<Array<ResolversTypes['ShopProductOption']>, ParentType, ContextType>;
  BookingSettings?: Resolver<Maybe<ResolversTypes['BookingTemplateSettings']>, ParentType, ContextType>;
  Pictures?: Resolver<Maybe<Array<ResolversTypes['S3File']>>, ParentType, ContextType>;
  Price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  Product?: Resolver<Maybe<ResolversTypes['PartialProduct']>, ParentType, ContextType>;
  Profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasOptions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventoryQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productVariantsQuantities?: Resolver<Array<ResolversTypes['ProductVariantQuantity']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  requiresAddress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stripeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thankYouMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trackInventory?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartialProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialProductVariant'] = ResolversParentTypes['PartialProductVariant']> = ResolversObject<{
  Pictures?: Resolver<Maybe<Array<ResolversTypes['S3File']>>, ParentType, ContextType>;
  Price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>;
  Product?: Resolver<Maybe<ResolversTypes['PartialProduct']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventoryQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PartialProductsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PartialProductsWithPagination'] = ResolversParentTypes['PartialProductsWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['PartialProduct']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = ResolversObject<{
  MerchantProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  Order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paidAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PaymentStatusEnum'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PaymentTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentIntentResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentIntent'] = ResolversParentTypes['PaymentIntent']> = ResolversObject<{
  bankTransferInfo?: Resolver<Maybe<ResolversTypes['BankTransferInfo']>, ParentType, ContextType>;
  clientSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentIntentResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentIntentResult'] = ResolversParentTypes['PaymentIntentResult']> = ResolversObject<{
  __resolveType: TypeResolveFn<'OrderPaymentIntentError' | 'PaymentResult', ParentType, ContextType>;
}>;

export type PaymentResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentResult'] = ResolversParentTypes['PaymentResult']> = ResolversObject<{
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentShareInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentShareInfo'] = ResolversParentTypes['PaymentShareInfo']> = ResolversObject<{
  MerchantProfile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRecurringDonation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PaymentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaymentsWithPagination'] = ResolversParentTypes['PaymentsWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  payments?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PersonalProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['PersonalProfile'] = ResolversParentTypes['PersonalProfile']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  socialMediaAccounts?: Resolver<Array<ResolversTypes['SocialMediaAccount']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  websiteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price']> = ResolversObject<{
  ShippingZones?: Resolver<Maybe<Array<ResolversTypes['ShippingZone']>>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiresAddress?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = ResolversObject<{
  AttributeProduct?: Resolver<Array<ResolversTypes['ShopProductOption']>, ParentType, ContextType>;
  Pictures?: Resolver<Array<ResolversTypes['S3File']>, ParentType, ContextType>;
  Price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  Profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasOptions?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductAttributeOrderValueAssignmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductAttributeOrderValueAssignment'] = ResolversParentTypes['ProductAttributeOrderValueAssignment']> = ResolversObject<{
  AttributeValue?: Resolver<ResolversTypes['AttributeValue'], ParentType, ContextType>;
  Order?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductSettings'] = ResolversParentTypes['ProductSettings']> = ResolversObject<{
  BankConnections?: Resolver<Array<ResolversTypes['BankConnection']>, ParentType, ContextType>;
  buttonColorScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buttonText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  donationCoverageFee?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  headingText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPersonalised?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mainText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thankYouMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetColorScheme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetIsPopup?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductType'] = ResolversParentTypes['ProductType']> = ResolversObject<{
  Attributes?: Resolver<Array<ResolversTypes['Attribute']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDigital?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPublic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isShippable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ProductTypeEnum']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']> = ResolversObject<{
  Attachments?: Resolver<Maybe<Array<ResolversTypes['Attachment']>>, ParentType, ContextType, Partial<ProductVariantAttachmentsArgs>>;
  AttributeProduct?: Resolver<Array<ResolversTypes['ShopProductOption']>, ParentType, ContextType>;
  AttributeVariant?: Resolver<Array<ResolversTypes['ShopProductOption']>, ParentType, ContextType>;
  BookingSettings?: Resolver<Maybe<ResolversTypes['ProductVariantBookingSettings']>, ParentType, ContextType>;
  Pictures?: Resolver<Array<ResolversTypes['S3File']>, ParentType, ContextType>;
  Price?: Resolver<ResolversTypes['Price'], ParentType, ContextType>;
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  Profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantBookingSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantBookingSettings'] = ResolversParentTypes['ProductVariantBookingSettings']> = ResolversObject<{
  daysInAdvance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  minimumNoticeInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantDateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantDate'] = ResolversParentTypes['ProductVariantDate']> = ResolversObject<{
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantOrder'] = ResolversParentTypes['ProductVariantOrder']> = ResolversObject<{
  Order?: Resolver<ResolversTypes['Order'], ParentType, ContextType>;
  ProductVariant?: Resolver<ResolversTypes['ProductVariant'], ParentType, ContextType>;
  TimeSlot?: Resolver<Maybe<ResolversTypes['BookingTimeSlot']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  productVariantId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantQuantityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantQuantity'] = ResolversParentTypes['ProductVariantQuantity']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantSlotResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSlot'] = ResolversParentTypes['ProductVariantSlot']> = ResolversObject<{
  availableCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductVariantSlotsSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariantSlotsSettings'] = ResolversParentTypes['ProductVariantSlotsSettings']> = ResolversObject<{
  breakInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  capacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dayInWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  daysInAdvance?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductsWithPagination'] = ResolversParentTypes['ProductsWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = ResolversObject<{
  ShopSettings?: Resolver<ResolversTypes['ShopSettings'], ParentType, ContextType>;
  Tiers?: Resolver<Array<ResolversTypes['Tier']>, ParentType, ContextType>;
  Website?: Resolver<Maybe<ResolversTypes['Website']>, ParentType, ContextType>;
  coverPicture?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasShopProducts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  labelColorScheme?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productSettings?: Resolver<ResolversTypes['ProductSettings'], ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  socialMediaAccounts?: Resolver<Array<ResolversTypes['SocialMediaAccount']>, ParentType, ContextType>;
  stripeChargesEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stripeConnectId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  supportersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  websiteUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProfilesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfilesWithPagination'] = ResolversParentTypes['ProfilesWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  profiles?: Resolver<Array<ResolversTypes['Profile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PushNotificationSubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PushNotificationSubscription'] = ResolversParentTypes['PushNotificationSubscription']> = ResolversObject<{
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allProducts?: Resolver<Array<ResolversTypes['SSGProduct']>, ParentType, ContextType, Partial<QueryAllProductsArgs>>;
  allProfiles?: Resolver<Array<ResolversTypes['SSGProfile']>, ParentType, ContextType>;
  applicationFees?: Resolver<Array<ResolversTypes['ApplicationFee']>, ParentType, ContextType>;
  availableTimeSlots?: Resolver<Array<ResolversTypes['ProductVariantSlot']>, ParentType, ContextType, RequireFields<QueryAvailableTimeSlotsArgs, 'month' | 'productVariantId' | 'year'>>;
  backofficeOrders?: Resolver<ResolversTypes['OrdersWithPagination'], ParentType, ContextType, RequireFields<QueryBackofficeOrdersArgs, 'pagination'>>;
  backofficeProduct?: Resolver<Maybe<ResolversTypes['PartialProduct']>, ParentType, ContextType, RequireFields<QueryBackofficeProductArgs, 'id'>>;
  backofficeProductVariants?: Resolver<Array<ResolversTypes['PartialProductVariant']>, ParentType, ContextType, RequireFields<QueryBackofficeProductVariantsArgs, 'productId'>>;
  backofficeProducts?: Resolver<ResolversTypes['PartialProductsWithPagination'], ParentType, ContextType, RequireFields<QueryBackofficeProductsArgs, 'pagination'>>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<QueryBookingArgs, 'timeSlotId'>>;
  bookings?: Resolver<ResolversTypes['BookingsWithPagination'], ParentType, ContextType, RequireFields<QueryBookingsArgs, 'filter' | 'pagination'>>;
  communityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<QueryCommunityPostArgs, 'id'>>;
  communityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<QueryCommunityPostCommentArgs, 'id'>>;
  communityPostComments?: Resolver<ResolversTypes['CommunityPostCommentsWithPagination'], ParentType, ContextType, RequireFields<QueryCommunityPostCommentsArgs, 'communityPostId' | 'pagination'>>;
  communityPosts?: Resolver<ResolversTypes['CommunityPostsWithPagination'], ParentType, ContextType, RequireFields<QueryCommunityPostsArgs, 'pagination'>>;
  creatorPost?: Resolver<Maybe<ResolversTypes['CreatorPost']>, ParentType, ContextType, RequireFields<QueryCreatorPostArgs, 'id'>>;
  creatorPosts?: Resolver<ResolversTypes['CreatorPostsWithPagination'], ParentType, ContextType, RequireFields<QueryCreatorPostsArgs, 'filterData' | 'pagination'>>;
  currentProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType, RequireFields<QueryCurrentProfileArgs, 'userId'>>;
  instantPayment?: Resolver<ResolversTypes['InstantPayment'], ParentType, ContextType, RequireFields<QueryInstantPaymentArgs, 'id'>>;
  memberships?: Resolver<ResolversTypes['MembershipsWithPagination'], ParentType, ContextType, RequireFields<QueryMembershipsArgs, 'pagination' | 'tierId'>>;
  merchantStats?: Resolver<Maybe<ResolversTypes['MerchantStats']>, ParentType, ContextType, Partial<QueryMerchantStatsArgs>>;
  myCards?: Resolver<Array<ResolversTypes['Card']>, ParentType, ContextType>;
  myCarts?: Resolver<Array<ResolversTypes['CartSummary']>, ParentType, ContextType>;
  myPayments?: Resolver<ResolversTypes['PaymentsWithPagination'], ParentType, ContextType, RequireFields<QueryMyPaymentsArgs, 'pagination' | 'type'>>;
  myProfiles?: Resolver<Array<ResolversTypes['Profile']>, ParentType, ContextType>;
  mySubscriptions?: Resolver<ResolversTypes['MembershipsWithPagination'], ParentType, ContextType, RequireFields<QueryMySubscriptionsArgs, 'pagination' | 'status'>>;
  order?: Resolver<Maybe<ResolversTypes['Order']>, ParentType, ContextType, Partial<QueryOrderArgs>>;
  paymentShare?: Resolver<ResolversTypes['PaymentShareInfo'], ParentType, ContextType, RequireFields<QueryPaymentShareArgs, 'id'>>;
  personalProfile?: Resolver<Maybe<ResolversTypes['PersonalProfile']>, ParentType, ContextType, RequireFields<QueryPersonalProfileArgs, 'userId'>>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType, RequireFields<QueryProductArgs, 'id'>>;
  productTypes?: Resolver<Array<ResolversTypes['ProductType']>, ParentType, ContextType>;
  productVariant?: Resolver<Maybe<ResolversTypes['ProductVariant']>, ParentType, ContextType, Partial<QueryProductVariantArgs>>;
  products?: Resolver<ResolversTypes['ProductsWithPagination'], ParentType, ContextType, RequireFields<QueryProductsArgs, 'pagination' | 'username'>>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType, Partial<QueryProfileArgs>>;
  profiles?: Resolver<Maybe<ResolversTypes['ProfilesWithPagination']>, ParentType, ContextType, RequireFields<QueryProfilesArgs, 'pagination'>>;
  pushNotificationSubscription?: Resolver<Maybe<ResolversTypes['PushNotificationSubscription']>, ParentType, ContextType, RequireFields<QueryPushNotificationSubscriptionArgs, 'token'>>;
  supporters?: Resolver<ResolversTypes['SupportersWithPagination'], ParentType, ContextType, RequireFields<QuerySupportersArgs, 'pagination'>>;
  tier?: Resolver<Maybe<ResolversTypes['Tier']>, ParentType, ContextType, RequireFields<QueryTierArgs, 'id'>>;
  tiers?: Resolver<Array<ResolversTypes['Tier']>, ParentType, ContextType, RequireFields<QueryTiersArgs, 'profileId'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  websites?: Resolver<ResolversTypes['WebsitesWithPagination'], ParentType, ContextType, RequireFields<QueryWebsitesArgs, 'pagination'>>;
}>;

export type S3FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3File'] = ResolversParentTypes['S3File']> = ResolversObject<{
  Uploader?: Resolver<ResolversTypes['UserPublicDetails'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  encodingStatus?: Resolver<Maybe<ResolversTypes['StreamStatus']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signExpiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  signedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<S3FileThumbnailSignedUrlArgs, 'size'>>;
  type?: Resolver<ResolversTypes['FileTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type S3PayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3Payload'] = ResolversParentTypes['S3Payload']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SsgProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['SSGProduct'] = ResolversParentTypes['SSGProduct']> = ResolversObject<{
  productSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  variantSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SsgProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['SSGProfile'] = ResolversParentTypes['SSGProfile']> = ResolversObject<{
  tabs?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShippingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Shipping'] = ResolversParentTypes['Shipping']> = ResolversObject<{
  ShippingZone?: Resolver<Maybe<ResolversTypes['ShippingZone']>, ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deliveredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  trackingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShippingZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingZone'] = ResolversParentTypes['ShippingZone']> = ResolversObject<{
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countryCodes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  freeAbove?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isOther?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopProductOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopProductOption'] = ResolversParentTypes['ShopProductOption']> = ResolversObject<{
  attrId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  values?: Resolver<Array<ResolversTypes['ShopProductOptionValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopProductOptionValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopProductOptionValue'] = ResolversParentTypes['ShopProductOptionValue']> = ResolversObject<{
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valueId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ShopSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShopSettings'] = ResolversParentTypes['ShopSettings']> = ResolversObject<{
  ShippingZones?: Resolver<Array<ResolversTypes['ShippingZone']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SlotSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlotSettings'] = ResolversParentTypes['SlotSettings']> = ResolversObject<{
  dayInWeek?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fromTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  toTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialMediaAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SocialMediaAccount'] = ResolversParentTypes['SocialMediaAccount']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  network?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StatResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stat'] = ResolversParentTypes['Stat']> = ResolversObject<{
  growth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StreamResolvers<ContextType = any, ParentType extends ResolversParentTypes['Stream'] = ResolversParentTypes['Stream']> = ResolversObject<{
  signedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['StreamStatus']>, ParentType, ContextType>;
  uid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  _?: SubscriptionResolver<Maybe<ResolversTypes['Boolean']>, "_", ParentType, ContextType>;
  newOrder?: SubscriptionResolver<Maybe<ResolversTypes['NewOrder']>, "newOrder", ParentType, ContextType, RequireFields<SubscriptionNewOrderArgs, 'profileId' | 'status'>>;
  productPurchase?: SubscriptionResolver<ResolversTypes['ProductVariantOrder'], "productPurchase", ParentType, ContextType>;
}>;

export type SubscriptionDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubscriptionData'] = ResolversParentTypes['SubscriptionData']> = ResolversObject<{
  clientSecret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SupportersWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SupportersWithPagination'] = ResolversParentTypes['SupportersWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  supporters?: Resolver<Array<ResolversTypes['Payment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TierResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tier'] = ResolversParentTypes['Tier']> = ResolversObject<{
  Product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  amountPerMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRecurringDonation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  membersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  profileUsername?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  welcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateProductVariantOrderDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateProductVariantOrderData'] = ResolversParentTypes['UpdateProductVariantOrderData']> = ResolversObject<{
  isTooBig?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpsertOrderContactInfoDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpsertOrderContactInfoData'] = ResolversParentTypes['UpsertOrderContactInfoData']> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  contactInfo?: Resolver<Maybe<ResolversTypes['ContactInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailVerified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoleEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserPublicDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPublicDetails'] = ResolversParentTypes['UserPublicDetails']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WebsiteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Website'] = ResolversParentTypes['Website']> = ResolversObject<{
  Profile?: Resolver<ResolversTypes['Profile'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WebsitesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['WebsitesWithPagination'] = ResolversParentTypes['WebsitesWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  websites?: Resolver<Array<ResolversTypes['Website']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Address?: AddressResolvers<ContextType>;
  ApplicationFee?: ApplicationFeeResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  Attribute?: AttributeResolvers<ContextType>;
  AttributeProduct?: AttributeProductResolvers<ContextType>;
  AttributeValue?: AttributeValueResolvers<ContextType>;
  AttributeVariant?: AttributeVariantResolvers<ContextType>;
  AttributeVariantExtend?: AttributeVariantExtendResolvers<ContextType>;
  BankConnection?: BankConnectionResolvers<ContextType>;
  BankTransferInfo?: BankTransferInfoResolvers<ContextType>;
  Booking?: BookingResolvers<ContextType>;
  BookingTemplateSettings?: BookingTemplateSettingsResolvers<ContextType>;
  BookingTimeSlot?: BookingTimeSlotResolvers<ContextType>;
  BookingsWithPagination?: BookingsWithPaginationResolvers<ContextType>;
  Card?: CardResolvers<ContextType>;
  CartSummary?: CartSummaryResolvers<ContextType>;
  CheckOrderItemsAvailabilityReturnItem?: CheckOrderItemsAvailabilityReturnItemResolvers<ContextType>;
  CommunityPost?: CommunityPostResolvers<ContextType>;
  CommunityPostAuthor?: CommunityPostAuthorResolvers<ContextType>;
  CommunityPostComment?: CommunityPostCommentResolvers<ContextType>;
  CommunityPostCommentsWithPagination?: CommunityPostCommentsWithPaginationResolvers<ContextType>;
  CommunityPostsWithPagination?: CommunityPostsWithPaginationResolvers<ContextType>;
  ContactInfo?: ContactInfoResolvers<ContextType>;
  CreatorPost?: CreatorPostResolvers<ContextType>;
  CreatorPostsWithPagination?: CreatorPostsWithPaginationResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  InstantPayment?: InstantPaymentResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  Membership?: MembershipResolvers<ContextType>;
  MembershipsWithPagination?: MembershipsWithPaginationResolvers<ContextType>;
  MerchantStats?: MerchantStatsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NewOrder?: NewOrderResolvers<ContextType>;
  Order?: OrderResolvers<ContextType>;
  OrderPaymentIntentError?: OrderPaymentIntentErrorResolvers<ContextType>;
  OrdersWithPagination?: OrdersWithPaginationResolvers<ContextType>;
  Pagination?: PaginationResolvers<ContextType>;
  PartialProduct?: PartialProductResolvers<ContextType>;
  PartialProductVariant?: PartialProductVariantResolvers<ContextType>;
  PartialProductsWithPagination?: PartialProductsWithPaginationResolvers<ContextType>;
  Payment?: PaymentResolvers<ContextType>;
  PaymentIntent?: PaymentIntentResolvers<ContextType>;
  PaymentIntentResult?: PaymentIntentResultResolvers<ContextType>;
  PaymentResult?: PaymentResultResolvers<ContextType>;
  PaymentShareInfo?: PaymentShareInfoResolvers<ContextType>;
  PaymentsWithPagination?: PaymentsWithPaginationResolvers<ContextType>;
  PersonalProfile?: PersonalProfileResolvers<ContextType>;
  Price?: PriceResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductAttributeOrderValueAssignment?: ProductAttributeOrderValueAssignmentResolvers<ContextType>;
  ProductSettings?: ProductSettingsResolvers<ContextType>;
  ProductType?: ProductTypeResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  ProductVariantBookingSettings?: ProductVariantBookingSettingsResolvers<ContextType>;
  ProductVariantDate?: ProductVariantDateResolvers<ContextType>;
  ProductVariantOrder?: ProductVariantOrderResolvers<ContextType>;
  ProductVariantQuantity?: ProductVariantQuantityResolvers<ContextType>;
  ProductVariantSlot?: ProductVariantSlotResolvers<ContextType>;
  ProductVariantSlotsSettings?: ProductVariantSlotsSettingsResolvers<ContextType>;
  ProductsWithPagination?: ProductsWithPaginationResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  ProfilesWithPagination?: ProfilesWithPaginationResolvers<ContextType>;
  PushNotificationSubscription?: PushNotificationSubscriptionResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  S3File?: S3FileResolvers<ContextType>;
  S3Payload?: S3PayloadResolvers<ContextType>;
  SSGProduct?: SsgProductResolvers<ContextType>;
  SSGProfile?: SsgProfileResolvers<ContextType>;
  Shipping?: ShippingResolvers<ContextType>;
  ShippingZone?: ShippingZoneResolvers<ContextType>;
  ShopProductOption?: ShopProductOptionResolvers<ContextType>;
  ShopProductOptionValue?: ShopProductOptionValueResolvers<ContextType>;
  ShopSettings?: ShopSettingsResolvers<ContextType>;
  SlotSettings?: SlotSettingsResolvers<ContextType>;
  SocialMediaAccount?: SocialMediaAccountResolvers<ContextType>;
  Stat?: StatResolvers<ContextType>;
  Stream?: StreamResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionData?: SubscriptionDataResolvers<ContextType>;
  SupportersWithPagination?: SupportersWithPaginationResolvers<ContextType>;
  Tier?: TierResolvers<ContextType>;
  UpdateProductVariantOrderData?: UpdateProductVariantOrderDataResolvers<ContextType>;
  UpsertOrderContactInfoData?: UpsertOrderContactInfoDataResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserPublicDetails?: UserPublicDetailsResolvers<ContextType>;
  Website?: WebsiteResolvers<ContextType>;
  WebsitesWithPagination?: WebsitesWithPaginationResolvers<ContextType>;
}>;

