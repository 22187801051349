import React from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import {
  AspectRatio,
  Box,
  chakra,
  Flex,
  Link,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
// import { GoSearch } from 'react-icons/go'
import { getRoute } from 'utils/helpers'
import { NotificationDot } from 'components/NotificationDot'
import { /*HeartIcon, */ ShoppingBagIcon } from '../Icons'
import { InputWrapper } from '../InputWrapper'
import Logo from '../Logo'
import { MyCartsModal, useShopContext } from '../Shop'
import { User } from './User'
import { LanguagePick } from './LanguagePick'

const MenuLink = chakra(Link, {
  baseStyle: {
    color: 'primary.600',
    textAlign: 'center',
    lineHeight: 'shorter',
    display: { base: 'none', md: 'block' },
  },
})

interface HeaderContentProps {
  hasRightContent?: boolean
  isStickyByDefault?: boolean
}

export function HeaderContent({ hasRightContent }: HeaderContentProps) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { myCarts, shouldShowAddedToCartPopover } = useShopContext()
  const myCartsModalToggle = useDisclosure()
  const routerUsername = router.query?.username as string | undefined
  const cartUsername = myCarts[0]?.MerchantProfile.username || routerUsername
  const cartItemsCount = myCarts.reduce((acc, { itemsCount }) => acc + itemsCount, 0)
  const hasProducts = cartItemsCount > 0

  React.useEffect(() => {
    router.events.on('beforeHistoryChange', myCartsModalToggle.onClose)
    return () => router.events.off('beforeHistoryChange', myCartsModalToggle.onClose)
  }, [])

  React.useEffect(() => {}, [cartItemsCount])

  return (
    <>
      <Flex w="100%" h="16" px={{ base: '4', md: '6' }} align="center" justify="space-between">
        <Flex align="center">
          <AspectRatio
            ratio={2.5}
            width={{ base: '130px', sm: '140px', md: '174px' }}
            href="/"
            as={NextLink}
            display="block"
            aria-label="Plantico, Back to homepage"
          >
            <Logo width="100%" height="100%" />
          </AspectRatio>
        </Flex>

        {hasRightContent && (
          <Flex
            justify="flex-end"
            w="100%"
            align="center"
            color="gray.400"
            maxW="1100px"
            fontSize={{
              base: 'sm',
              md: 'md',
            }}
            pl={{
              base: '2.5',
              md: '8',
            }}
          >
            <HStack spacing="6">
              <HStack
                spacing={{
                  base: '4',
                  sm: '7',
                  lg: '10',
                }}
              >
                {false && (
                  <MenuLink as={NextLink} href="#">
                    Community
                  </MenuLink>
                )}
                <HStack spacing="4">
                  {false && (
                    <>
                      <IconButton
                        aria-label="Search"
                        borderRadius="lg"
                        w="10"
                        h="10"
                        px="0"
                        py="0"
                        display={{ base: 'flex', md: 'none' }}
                        // icon={<Icon as={GoSearch} width="4" height="4" color="white" />}
                        onClick={() => console.log('TODO open search modal')}
                      />
                      <Box position="relative" display={{ base: 'none', md: 'block' }}>
                        <form onSubmit={(event) => event.preventDefault()}>
                          {/* TODO: Powerful search like algolia across the whole site */}
                          {/* Bookings, recent purchases, people you follow, choose category */}
                          <InputWrapper
                            w="13rem"
                            h="3.25rem"
                            minH="3.25rem"
                            pr="14"
                            placeholder="Search..."
                          />
                          <IconButton
                            aria-label="Search"
                            type="submit"
                            position="absolute"
                            right="2"
                            top="50%"
                            transform="translateY(-50%)"
                            borderRadius="lg"
                            w="10"
                            h="10"
                            px="0"
                            py="0"
                            // icon={<Icon as={GoSearch} width="4" height="4" color="white" />}
                          />
                        </form>
                      </Box>
                    </>
                  )}

                  <LanguagePick />

                  <Popover
                    isOpen={shouldShowAddedToCartPopover}
                    closeOnBlur={false}
                    isLazy
                    placement="bottom-end"
                  >
                    <HStack>
                      <PopoverAnchor>
                        <chakra.button
                          cursor="pointer"
                          position="relative"
                          // w="8"
                          h="8"
                          onClick={
                            myCarts.length > 1
                              ? myCartsModalToggle.onOpen
                              : () =>
                                  router.push(
                                    cartUsername ? getRoute('cart', cartUsername) : getRoute('cart')
                                  )
                          }
                        >
                          <HStack spacing={0.25}>
                            <Icon as={ShoppingBagIcon} width="8" height="8" color="primary.500" />
                            <Box w={hasProducts ? 'auto' : '0'} overflow="hidden">
                              <NotificationDot
                                opacity={hasProducts ? 1 : 0}
                                transition="all 0.5s ease-out"
                                transform={hasProducts ? 'translateX(0)' : 'translateX(100%)'}
                              >
                                <Text fontSize="sm" fontWeight="medium">
                                  {cartItemsCount}
                                </Text>
                              </NotificationDot>
                            </Box>
                          </HStack>
                        </chakra.button>
                      </PopoverAnchor>
                    </HStack>

                    <PopoverContent backgroundColor="green.400" w="max-content" borderWidth="3px">
                      <PopoverArrow backgroundColor="green.400" />
                      <PopoverBody>
                        <Text textColor="white" fontSize="sm">
                          {t('Header.productWasAddedToCart')}
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  {/* <Icon as={HeartIcon} width="8" height="8" color="primary.500" cursor="pointer" /> */}
                  <User />
                </HStack>
              </HStack>
            </HStack>
          </Flex>
        )}
      </Flex>
      {myCarts.length > 1 && (
        <MyCartsModal isOpen={myCartsModalToggle.isOpen} onClose={myCartsModalToggle.onClose} />
      )}
    </>
  )
}
