import React from 'react'
import { FiHeart } from 'react-icons/fi'
import { IconType } from 'react-icons/lib'
import { CurrentProfileQuery, useMerchantStatsQuery } from 'src/generated/graphql-frontend'
import { MerchantStats, Stat } from 'src/generated/graphql-backend'
import { CustomStat } from 'components/CustomStat'
import { StatsGrid } from 'components/StatsGrid'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  profile?: NonNullable<NonNullable<CurrentProfileQuery['currentProfile']>> | null
  isDisabled?: boolean
}

export const ProfileStats: React.FC<Props> = ({ profile, isDisabled }) => {
  const { t } = useTranslation('settings')
  const { data, loading } = useMerchantStatsQuery({
    variables: {
      profileId: profile?.id,
    },
    skip: !profile?.id,
  })
  const merchantStats = data?.merchantStats

  const stats = React.useMemo<
    {
      key: keyof MerchantStats
      labelIcon: IconType
      label: string
      differenceExtra: string
      isCurrency?: boolean
    }[]
  >(() => {
    const last7DaysText = t('PayoutTab.lastNDays', { days: 7 })
    const last30DaysText = t('PayoutTab.lastNDays', { days: 30 })

    return [
      {
        key: 'supporters',
        labelIcon: FiHeart,
        label: t('PayoutTab.supporters'),
        differenceExtra: t('PayoutTab.vsLastNDays', {
          lastNDays: last7DaysText.toLocaleLowerCase(),
        }),
      },
      {
        key: 'last7',
        labelIcon: FiHeart,
        label: last7DaysText,
        differenceExtra: t('PayoutTab.vsLastNDays', {
          lastNDays: last7DaysText.toLocaleLowerCase(),
        }),
        isCurrency: true,
      },
      {
        key: 'last30',
        labelIcon: FiHeart,
        label: last30DaysText,
        differenceExtra: t('PayoutTab.vsLastNDays', {
          lastNDays: last30DaysText.toLocaleLowerCase(),
        }),
        isCurrency: true,
      },
      {
        key: 'allTime',
        labelIcon: FiHeart,
        label: t('PayoutTab.allTimeTotal'),
        differenceExtra: 'overall',
        isCurrency: true,
      },
    ]
  }, [t])

  return (
    <StatsGrid>
      {stats.map(({ key, labelIcon, label, differenceExtra, isCurrency }) => {
        const stat = merchantStats?.[key] as Stat | undefined
        return (
          <CustomStat
            key={key}
            labelIcon={labelIcon}
            label={label}
            isCurrency={isCurrency}
            isLoading={loading}
            currencySymbol={profile?.currency}
            {...(isDisabled
              ? { number: 0, isDisabled }
              : {
                  number: stat?.value,
                  difference: stat?.growth,
                  differenceExtra,
                })}
          />
        )
      })}
    </StatsGrid>
  )
}
